import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import { environment } from '../../../../environments/environment'
import {
  LoggedInCallback,
  UserLoginService,
} from '../../../service/user-login.service'

declare var window: any

@Component({
  selector: 'google-signin',
  templateUrl: './google-signin.html',
})
export class GoogleSigninComponent {
  public googleInit() {
    window.google.accounts.id.initialize({
      client_id: environment.googleClientId,
      callback: (token) => {
        this.userLogin.onLoginSuccess(token)
      },
    })
    window.google.accounts.id.prompt()
  }

  constructor(private userLogin: UserLoginService) {
    userLogin.isAuthenticated(this)
  }

  isLoggedIn(_, loggedIn: boolean) {
    if (!loggedIn) {
      this.googleInit()
    }
  }
  signOut(): void {
    window.google.accounts.id.revoke(environment.googleClientId)
    this.userLogin.onLogoutSuccess()
  }
}

@Component({
  selector: 'google-signout',
  templateUrl: './google-signout.html',
})
export class LogoutComponent implements OnInit, LoggedInCallback {
  constructor(public router: Router, public userService: UserLoginService) {
    this.userService.isAuthenticated(this)
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (isLoggedIn) {
      this.router.navigate(['/'])
    }
  }

  ngOnInit() {}
}
