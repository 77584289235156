<h1 style="text-align: center; margin: 0" color="primary" mat-dialog-title>
  Mettre à jour
</h1>
<div mat-dialog-content>
  <form class="example-form">
    <mat-form-field class="example-full-width">
      <input
        #code
        readonly="true"
        matInput
        placeholder="Code contrat"
        [value]="contrat"
      />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-select
        #an
        placeholder="Année"
        [value]="annee"
        (selectionChange)="selectAnnee()"
      >
        <mat-option *ngIf="booleanAnnee" [value]="annee">
          {{annee}}
        </mat-option>
        <mat-option *ngFor="let element of moment" [value]="element">
          {{element}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-select #coded placeholder="Code Domaine" [value]="code_dmn_fonc">
        <mat-option
          *ngFor="let element of listCodeDomaine"
          [value]="element"
          (click)="selectCodeDomaine(element)"
        >
          {{element}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-select
        #rubriq
        placeholder="Rubrique"
        [(value)]="rubrique"
        (selectionChange)="selectRubrique($event.value)"
      >
        <mat-option *ngFor="let element of listRubrique" [value]="element">
          {{element}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-select
        #sousrubrique
        placeholder="Sous Rubrique"
        [(value)]="sous_rubrique"
      >
        <mat-option *ngFor="let element of rubriqueSelected" [value]="element">
          {{element}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input
        matInput
        #domaine
        placeholder="Libelle Domaine"
        type="text"
        style="color: #333"
        disabled
        [(value)]="l_domaine"
      />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input
        #unity
        readonly="true"
        matInput
        placeholder="Unite"
        [value]="unite"
      />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input
        #val
        matInput
        type="number"
        placeholder="Valeur"
        [value]="valeur"
      />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input
        matInput
        placeholder="Dernier Modificateur"
        type="text"
        style="color: #333"
        disabled
        [value]="email"
      />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input
        matInput
        placeholder="Dernière modification"
        type="date"
        style="color: #333"
        disabled
        [value]="modified_on"
      />
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input
        matInput
        placeholder="Motif (*)"
        type="text"
        style="color: #333"
        disabled
        [value]="motif"
      />
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input
        matInput
        placeholder="Dernier Commentaire (*)"
        type="text"
        style="color: #333"
        disabled
        [value]="commentaire"
      />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <mat-form-field class="example-full-width" hidden>
    <mat-select placeholder="* Motif requis" #mot disabled>
      <mat-option *ngFor="let element of listMotif" [value]="element[0]">
        {{element[1]}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="example-full-width">
    <input
      matInput
      minlength="10"
      #comment
      placeholder="* Commentaire requis"
      type="text"
      [value]=""
    />
  </mat-form-field>
  <p
    style="color: red; display: inline-block; width: 50%"
    *ngIf="error && error.errorMessage"
  >
    {{error.errorMessage}}
  </p>
  <button
    style="display: inline-block; margin: auto"
    mat-button
    color="warn"
    [mat-dialog-close]="false"
  >
    Retour
  </button>
  <button
    style="display: inline-block; margin: auto"
    mat-button
    color="primary"
    (click)="onConfirmClick()"
    cdkFocusInitial
  >
    Mettre à jour
  </button>
</div>
