<div>
  <mat-toolbar color="primary" style="background-color: #f49f25">
    <div style="height: 100%; display: block; margin-left: 5%">
      <img
        style="
          max-height: 60%;
          border-right: 1px solid #ccc;
          padding-right: 15px;
        "
        src="assets/img/logo.png"
      />
      <p class="current-app">Veolia Au Contrat</p>
      <img
        style="max-height: 100%"
        [src]="envimg"
        onerror="this.style.display='none'"
      />
    </div>
    <span
      style="display: inline-block; color: #fff; margin-left: auto"
      class="fill-remaining-space"
    >
      <button mat-icon-button [matMenuTriggerFor]="menu">
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" [overlapTrigger]="false">
        <button mat-menu-item>
          <mat-icon>exit_to_app</mat-icon>
          <span> <a (click)="logout()">Logout</a></span>
        </button>
        <button mat-menu-item>
          <mat-icon>exit_to_app</mat-icon>
          <span> <a [routerLink]="['secureHome/help']">Aide</a></span>
        </button>
      </mat-menu>
    </span>
  </mat-toolbar>
</div>
<router-outlet></router-outlet>
<footer style="position: fixed; bottom: 0; margin: 0; padding: 0">
  <img style="max-width: 100%" src="assets/img/footer.png" />
</footer>
