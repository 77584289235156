// Angular modules
// HttpClientModule is useful to load mocks and can be removed in the future
import { HttpClientModule } from '@angular/common/http'
import { NgModule } from '@angular/core'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { MatAutocompleteModule } from '@angular/material/autocomplete'
import { MatButtonModule } from '@angular/material/button'
import { MatCardModule } from '@angular/material/card'
import { MatCheckboxModule } from '@angular/material/checkbox'
import { MatDatepickerModule } from '@angular/material/datepicker'
import { MatDialogModule } from '@angular/material/dialog'
import { MatExpansionModule } from '@angular/material/expansion'
import { MatIconModule } from '@angular/material/icon'
import { MatInputModule } from '@angular/material/input'
import { MatListModule } from '@angular/material/list'
import { MatMenuModule } from '@angular/material/menu'
import { MatPaginatorModule } from '@angular/material/paginator'
import { MatProgressBarModule } from '@angular/material/progress-bar'
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner'
import { MatSelectModule } from '@angular/material/select'
import { MatSortModule } from '@angular/material/sort'
import { MatTableModule } from '@angular/material/table'
import { MatTabsModule } from '@angular/material/tabs'
import { MatToolbarModule } from '@angular/material/toolbar'
import { BrowserModule } from '@angular/platform-browser'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { PdfViewerModule } from 'ng2-pdf-viewer'
// External libraries
import { AppRoutingModule } from './app-routing.module'
// Basic routing
import { AppComponent } from './app.component'
import { ErrorModalComponent } from './global/errorModal/errorModal.component'
import { InfoModalComponent } from './global/infoModal/infoModal.component'
// Authentication
import {
  GoogleSigninComponent,
  LogoutComponent,
} from './public/auth/google-signin/google-signin.component'
// VAC components
import { HomeComponent, HomeLandingComponent } from './public/home.component'
import { CsvImportComponent } from './secure/csvImport/csvImport.component'
import { DocImportComponent } from './secure/docImport/docImport.component'
import { HelpComponent } from './secure/help/help.component'
import {
  InputDataComponent,
  UpdateDialogComponent,
  WarningDialogComponent,
} from './secure/inputData/inputData.component'
import {
  GlobalCepComponent,
  UpdateCepComponent,
  WarnCepDeletionComponent,
} from './secure/globalCep/globalCep.component'
import { CepDocComponent } from './secure/cepDoc/cepDoc.component'
import {
  SecureHomeComponent,
  SecureHomeLandingComponent,
} from './secure/secureHome.component'
// Cloud providers
import { AwsService } from './service/aws.service'
import { CloudEndpointService } from './service/cloud-endpoint.service'
import { UserLoginService } from './service/user-login.service'
// Utils (global methods)
import { UtilsService } from './service/utils.service'

@NgModule({
    declarations: [
        AppComponent,
        CsvImportComponent,
        DocImportComponent,
        HomeLandingComponent,
        HomeComponent,
        InputDataComponent,
        GlobalCepComponent,
        CepDocComponent,
        HelpComponent,
        GoogleSigninComponent,
        LogoutComponent,
        SecureHomeLandingComponent,
        SecureHomeComponent,
        ErrorModalComponent,
        InfoModalComponent,
        WarningDialogComponent,
        WarnCepDeletionComponent,
        UpdateDialogComponent,
        UpdateCepComponent,
        // DemoApp
    ],
    imports: [
        AppRoutingModule,
        BrowserModule,
        FormsModule,
        // HttpClientModule is useful to load mocks and can be removed in the future
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,
        MatButtonModule,
        MatCheckboxModule,
        MatToolbarModule,
        MatInputModule,
        MatProgressSpinnerModule,
        MatCardModule,
        MatMenuModule,
        MatIconModule,
        MatTabsModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        MatSelectModule,
        MatSortModule,
        MatTableModule,
        MatPaginatorModule,
        MatExpansionModule,
        MatDialogModule,
        MatListModule,
        MatProgressBarModule,
        MatDatepickerModule,
        PdfViewerModule,
        // DragAndDropModule
    ],
    providers: [AwsService, CloudEndpointService, UserLoginService, UtilsService],
    bootstrap: [
        AppComponent, // DemoApp
    ]
})
export class AppModule {}
