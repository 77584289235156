import { enableProdMode } from '@angular/core'
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic'
import { AppModule } from './app/app.module'
import { environment } from './environments/environment'

if (environment.production) {
  enableProdMode()
}
console.log = console.warn
const tmp = console.error
console.error = (...x) => {
  try {
    const req = new XMLHttpRequest()
    req.open('POST', environment.apiEndpoint + '/tbEmergencyLogger', false)
    req.setRequestHeader('Content-Type', 'application/json')
    req.send(JSON.stringify(x))
  } catch (e) {
    tmp('Logging error')
    try {
      tmp(e.toString())
    } catch (err) {
      tmp('Error cannot be log:' + err.toString())
    }
    return
  }
  return tmp(...x)
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
