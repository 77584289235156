import { HttpClient } from '@angular/common/http'
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { API, Cache } from 'aws-amplify'
import { ApiError } from '../../classes/apiError'
import { InfoMessage } from '../../classes/infoMessage'
import { CloudEndpointService } from '../../service/cloud-endpoint.service'
import {
  LoggedInCallback,
  UserLoginService,
} from '../../service/user-login.service'

@Component({
  selector: 'trvs-vac-front-app',
  templateUrl: './docImport.html',
})
export class DocImportComponent implements OnInit, LoggedInCallback {
  mess: string[] = []
  // Generated form group
  form: FormGroup = null

  // Loading lock inside form
  loading = false

  // API error
  error: ApiError

  // Info message when upload finished
  info: InfoMessage

  // Input form element
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef // File
  @ViewChild('code_contrat', { static: false }) code_contrat: ElementRef // Int (Select)
  @ViewChild('type_doc', { static: false }) type_doc: ElementRef // Int (Select)
  @ViewChild('date_effet_doc', { static: false }) date_effet_doc: ElementRef // String (date)
  @ViewChild('warning', { static: false }) warning: ElementRef // String (date)

  public listType = []
  public listContrat = []

  /**
   * Basic constructor
   */
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private userService: UserLoginService,
    private cloudEndpointService: CloudEndpointService,
    private formBuilder: FormBuilder,
    private httpClient: HttpClient
  ) {
    this.userService.isAuthenticated(this)
    this.createForm()
  }

  ngOnInit() {
    this.cloudEndpointService.loadLibelle().then((data) => {
      this.listType = data['DC'] || [[1, 'vide']]
    })
    this.cloudEndpointService.loadContracts().then((data) => {
      this.listContrat = data['code_contrat']
      this.listContrat.sort()
    })
  }

  /**
   * Login firewall redirection (will reject user to /home if not logged in).
   */
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/'])
    }
  }

  private createForm() {
    // Attach a validator to form (basic: if empty = rejected)
    this.form = this.formBuilder.group({
      name: [
        '',
        Validators.compose([Validators.required, Validators.pattern('.*.pdf')]),
      ],
      fileContent: [{}, Validators.required],
      code_contrat: [
        '',
        Validators.compose([
          Validators.required,
          Validators.pattern('[0-9A-Z]{5}'),
        ]),
      ],
      type_doc: ['', Validators.required],
      date_effet_doc: ['', Validators.required],
    })
  }

  onFileChange = (event: any) => {
    if (event.target.files.length > 0) {
      // Get file element from form
      const file = event.target.files[0]
      // Store content inside internal form object
      this.form.get('name').setValue(file.name)
      this.form.get('fileContent').setValue(file)
    }
  }

  onContractChange = (event: any, value = null) => {
    const code_contrat = value ? value : event.target.value
    // Store content inside internal form object
    this.form.get('code_contrat').setValue(code_contrat)
    if (
      code_contrat.length === 5 &&
      this.listContrat.indexOf(code_contrat) === -1
    ) {
      this.mess = [
        "Vérifiez le champs code contrat:<br />Ce code n'a pas encore été importé.",
      ]
    } else {
      this.mess = []
    }
  }

  onTypeChange = (event: any) => {
    const type_doc = event.value

    // Store content inside internal form object
    this.form.get('type_doc').setValue(type_doc)
  }

  onDateValeurChange = (event: any) => {
    const date_effet_doc = event.target.value

    // Store content inside internal form object
    this.form.get('date_effet_doc').setValue(date_effet_doc)
  }

  onSubmit = async () => {
    // Activate spinner
    this.loading = true
    const { token } = Cache.getItem('federatedInfo')

    const dataToSend = {}
    dataToSend['filename'] = this.form.get('name').value
    dataToSend['code_contrat'] = this.form.get('code_contrat').value
    dataToSend['type_doc'] = this.form.get('type_doc').value
    dataToSend['date_effet_doc'] = this.form.get('date_effet_doc').value

    const params = {
      headers: { 'X-VAC-google_id_token': token },
      body: dataToSend,
    }

    API.put('vac-api', '/docs', params)
      .then((res) => {
        this.httpClient
          .put(res.url, this.form.get('fileContent').value, {
            headers: { 'Content-Type': 'application/pdf' },
            responseType: 'arraybuffer',
          })
          .subscribe(
            (_) => {
              this.info = new InfoMessage()
              this.info.message = 'Téléchargement du fichier vers S3 terminée'
              this.loading = false
            },
            (err) => {
              console.error(err)
              this.error = new ApiError()
              this.error.errorMessage =
                'Erreur AWS: ' +
                ((err.response &&
                  err.response.data &&
                  err.response.data.Message) ||
                  err.message ||
                  err)
              this.mess = [this.error.errorMessage]
              this.loading = false
            }
          )
      })
      .catch((err) => {
        console.error(err)
        this.error = new ApiError()
        this.error.errorMessage =
          "Erreur à l'import: " +
          ((err.response && err.response.data && err.response.data.Message) ||
            err.message ||
            err)
        this.mess = [this.error.errorMessage]
        this.loading = false
      })
    return
  }

  /**
   * Clear form data
   */
  onCancel = () => {
    // Reset stored file content value
    this.form.get('fileContent').setValue({})
    this.form.get('name').setValue('')
    this.form.get('code_contrat').setValue('')
    this.form.get('type_doc').setValue('')
    this.form.get('date_effet_doc').setValue('')
    // Reset HTML element
    this.fileInput.nativeElement.value = ''
    this.code_contrat.nativeElement.value = ''
    this.type_doc.nativeElement.value = ''
    this.date_effet_doc.nativeElement.value = ''
    this.mess = []
    this.loading = false
  }
}
