import { Injectable } from '@angular/core'
import { API, Cache } from 'aws-amplify'

@Injectable()
export class CloudEndpointService {
  /**
   * Build a custom header containing Google JWT token in order to verify user info if needed.
   */
  private defaultHeaders(): any {
    try {
      const { token } = Cache.getItem('federatedInfo')
      return {
        'X-VAC-google_id_token': token,
      }
    } catch {
      return {}
    }
  }

  private apiGet(path: string, queryParameters: object): Promise<any> {
    const params = {
      headers: this.defaultHeaders(),
      queryStringParameters: queryParameters,
    }
    return API.get('vac-api', path, params)
  }

  private apiPut(
    path: string,
    bodyParameters: object,
    header: {} = null
  ): Promise<any> {
    const params = {
      headers: header || this.defaultHeaders(),
      body: bodyParameters,
    }
    return API.put('vac-api', path, params)
  }

  private apiPost(path: string, bodyParameters: object): Promise<any> {
    const params = {
      headers: this.defaultHeaders(),
      body: bodyParameters,
    }
    return API.post('vac-api', path, params)
  }

  private apiDelete(path: string): Promise<any> {
    const params = {
      headers: this.defaultHeaders(),
    }
    return API.del('vac-api', path, params)
  }

  /**
   * Public API endpoint, posting a new CSV to backend.
   */
  public pushJsonCsvData(data: object): Promise<any> {
    return this.apiPut('/prevu', data)
  }

  /**
   * Public API endpoint, posting a new Document to backend.
   */
  public pushDocs(data: object): Promise<any> {
    return this.apiPut('/docs', data, {
      'Content-Type': 'multipart/form-data',
    })
  }

  /**
   * Public API endpoint, loading the Ceps Global Informations from backend.
   */
  public getCepsJsonCsvData(contrat, annee): Promise<any> {
    return this.apiGet('/cep_prevu/' + contrat + '/' + annee, null)
  }
  /**
   * Public API endpoint, loading the Ceps Global Informations from backend.
   */
  public getDocumentsJsonCsvData(contrat): Promise<any> {
    return this.apiGet('/documents/' + contrat, null)
  }
  /**
   * Public API endpoint, loading ALL the CSV data from backend.
   */
  public getJsonCsvData(contrat, annee, unit): Promise<any> {
    return this.apiGet(
      '/prevu/' + contrat + '/' + annee + '/' + encodeURIComponent(unit),
      null
    )
  }

  /**
   * loadContracts isn't used since add filter on dataTable
   */
  public loadContracts(contracts?, years?, units?): Promise<any> {
    const params =
      contracts || years
        ? {
            contracts,
            years,
            units,
          }
        : null

    return this.apiGet('/contracts-years', params)
  }

  /**
   * Get all Libelles stored in db
   */
  public loadLibelle(): Promise<any> {
    return this.apiGet('/libelles', {})
  }

  /**
   * Delete a contract, posting instruction to API.
   */

  public deleteContract(id): Promise<any> {
    return this.apiDelete('/delete/' + id)
  }

  /**
   * Delete all contracts linked to current_cep, posting instruction to API.
   */

  public deleteCep(pk_sk: string, type: string): Promise<any> {
    return this.apiDelete('/delete_' + type + '/' + pk_sk)
  }
  /**
   * this.data["id"],this.new_annee['value'],this.new_code_dmn_fonc['value'],
   * this.new_rubrique['value'],this.new_sous_rubrique['value'],
   * this.new_l_domaine['value'],this.new_valeur.nativeElement['value']
   * Update a contract, posting instruction to API.
   */
  public updateContract(data: object): Promise<any> {
    return this.apiPut('/update/', data)
  }

  /**
   * Update a global Cep informations, posting fields to API.
   */
  public UpdateCepComponent(data: object): Promise<any> {
    return this.apiPut('/update_cep/', data)
  }
  constructor() {}
}
