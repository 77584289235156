export class Constants {

  // Some users have too large of a perimeter (national perimeter for example)
  // for the app to handle the whole list of contracts linked to their accounts
  public static largePerimeterUsers: string[] = [
    'beatrice.lemaire@veolia.com',
    'hong-kien.le@veolia.com',
    'morgane.le-quiniou@veolia.com',
    'julien.lacour@veolia.com',
    'alexandre.sangle-ferriere@veolia.com',
  ];
}
