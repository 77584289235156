import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'trvs-vac-front-app',
  templateUrl: './homeLanding.html',
})
export class HomeLandingComponent {
  constructor() {}
}

@Component({
  selector: 'trvs-vac-front-app',
  templateUrl: './home.html',
})
export class HomeComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
