<div>
  <mat-card style="text-align: center">
    <div class="row">
      <div class="col-lg-12">
        <h1>Centre d'aide VAC</h1>
        <br />
        <div class="row">
          <div class="col-lg-9">
            <pdf-viewer
              *ngIf="!img"
              [src]="pdfSrc"
              [show-borders]="true"
              [render-text]="true"
              style="display: block; width: 100%; height: 100%"
            ></pdf-viewer>
            <img *ngIf="img" src="{{img}}" />
          </div>
          <div class="col-lg-3">
            <mat-nav-list>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/release-note-7-VAC.pdf')"
              >
                Release Note 7
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/release-note-8-VAC.pdf')"
              >
                Release Note 8
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/release-note-9-VAC.pdf')"
              >
                Release Note 9
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/release-note-10-VAC.pdf')"
              >
                Release Note 10
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/release-note-11-VAC.pdf')"
              >
                Release Note 11
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/release-note-12-VAC.pdf')"
              >
                Release Note 12
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/release-note-13-VAC.pdf')"
              >
                Release Note 13
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/release-note-14-VAC.pdf')"
              >
                Release Note 14
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/release-note-15-VAC.pdf')"
              >
                Release Note 15
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/release-note-16-VAC.pdf')"
              >
                Release Note 16
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/release-note-20-VAC.pdf')"
              >
                Release Note 20
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/doc-3-Fiche-Deploiement-VAC.pdf')"
              >
                Fiche Déploiement
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/doc-2-Fiche-Memo-VAC.pdf')"
              >
                Fiche Mémo
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/mode_emploi_ihm.pdf')"
              >
                Mode d'emploi IHM
              </button>
              <button
                mat-list-item
                (click)="img = '/assets/doc/Fiche_synoptique.jpg'"
              >
                Fiche Synoptique
              </button>
              <button
                mat-list-item
                (click)="openDoc('/assets/doc/doc-7-Fiche-Parcours-Utilisateur-Benchmarks.pdf')"
              >
                Fiche Parcours Utilisateur Benchmarks
              </button>
              <a
                mat-list-item
                target="_blank"
                href="/assets/doc/doc-4-Modele-fichier-import-CEP.xlsx"
              >
                Modèle de fichier CEP (eau)
              </a>
              <a
                mat-list-item
                target="_blank"
                href="/assets/doc/doc-5-Modele_de_fichier_CEP_(Ass).xlsx"
              >
                Modèle de fichier CEP (Ass)
              </a>
              <a
                mat-list-item
                target="_blank"
                href="/assets/doc/Check-List-actions-a-mener-en-vue-dintegrer-CEP-CEPAGE.xlsx"
              >
                Checklist Intégration CEP Cep'age
              </a>
              <a
                mat-list-item
                target="_blank"
                href="/assets/doc/doc-6-Model_de_fichier_pour_import.csv"
              >
                Fichier-type pour IMPORT
              </a>
            </mat-nav-list>
          </div>
        </div>
      </div>
    </div>
  </mat-card>
</div>
