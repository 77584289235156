<h1 style="    text-align: center;
color:crimson" mat-dialog-title>
  Avertissement </h1>
<div mat-dialog-content>
  <p style="    text-align: center;
  ">{{message}}</p>
 
</div>
<div style=" margin-left: 90px
" mat-dialog-actions>
  <button mat-button color="warn" [mat-dialog-close]="no">Retour</button>
  <button mat-button  color="primary" [mat-dialog-close]="yes"  cdkFocusInitial>Confirmer</button>
</div>
