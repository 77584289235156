//
// Config file for production environment (AWS "prod" account)
//
import { commonEnvironment } from './environment.common'

export const environment = {
  production: true,

  img: '',
  common: commonEnvironment,

  // Global region for AWS Amplify configuration
  globalRegion: 'eu-west-1',

  // Identity region to Ireland
  identityRegion: 'eu-west-1',

  // API region for URL signing
  apiRegion: 'eu-west-1',

  apiKey: 'AIzaSyAND_iH-bTXyCQu6sK3gptMH7qDp87N6Ek',
  // PROD identifiers (allows only s3 origin)
  googleClientId:
    '780800443483-4c4a735a7up3lijeohjk8to6gfnujcfb.apps.googleusercontent.com',
  identityPoolId: 'eu-west-1:ab5109ab-4b50-4fe9-929c-00e237a0897f',
  apiEndpoint: 'https://6kwii67b58.execute-api.eu-west-1.amazonaws.com/v0',
}
