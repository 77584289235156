import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSelect } from '@angular/material/select'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import * as moment from 'moment'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { ApiError } from '../../classes/apiError'
import { CloudEndpointService } from '../../service/cloud-endpoint.service'
import {
  LoggedInCallback,
  UserLoginService,
} from '../../service/user-login.service'
import { Constants } from '../../global/Constants'

@Component({
  selector: 'update-item',
  templateUrl: 'updateItem.html',
})
export class UpdateDialogComponent implements OnInit {
  @ViewChild('code', { static: false }) new_contrat: ElementRef
  @ViewChild('an', { static: false }) new_annee: ElementRef
  @ViewChild('coded', { static: false }) new_code_dmn_fonc: ElementRef
  @ViewChild('rubriq', { static: false }) new_rubrique: ElementRef
  @ViewChild('sousrubrique', { static: false }) new_sous_rubrique: ElementRef
  @ViewChild('domaine', { static: false }) new_l_domaine: ElementRef
  @ViewChild('unity', { static: false }) new_unite: ElementRef
  @ViewChild('val', { static: false }) new_valeur: ElementRef
  @ViewChild('comment', { static: false }) new_comment: ElementRef
  @ViewChild('mot', { static: false }) new_motif: MatSelect
  public newdata = {}

  public spinner: boolean
  error: ApiError
  public contrat: string
  public annee: string
  public code_dmn_fonc: string
  public rubrique: string
  public sous_rubrique: string
  public l_domaine: string
  public unite: string
  public valeur: number
  public email: string
  public modified_on: string
  public commentaire: string
  public motif: string
  public listMotif = []
  public listCodeDomaine: string[]
  private DFPerCodeDF: { [key: string]: string }
  public listRubrique: string[]
  public moment = []
  public listSousRubriques: { [key: string]: string[] } = {}
  public rubriqueSelected: string[]
  public booleanAnnee = true

  constructor(
    public dialogRef: MatDialogRef<UpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputDataComponent,
    private cloudEndpointService: CloudEndpointService
  ) {}

  ngOnInit(): void {
    this.cloudEndpointService.loadLibelle().then((data) => {
      this.listMotif = data['MO'].sort((a, b) => a[0] < b[0])
      this.motif = this.listMotif[this.data['motif'] - 1][1]
      this.new_motif.value = this.listMotif[2][0]
      this.listCodeDomaine = []
      this.DFPerCodeDF = {}
      data['DF'].forEach((x: string[]) => {
        this.listCodeDomaine.push(x[0])
        this.DFPerCodeDF[x[0]] = x[1]
      })
      this.listRubrique = []
      data['RU'].forEach((el: string[]) => {
        const val = el[1].match(/[0-9A-D]{1,2}-[0-9]-/)
        if (!val) {
          this.listRubrique.push(el[1])
          return
        }
        const indx = el[1].match(/[0-9A-D]{1,2}/)[0]
        if (!this.listSousRubriques[indx]) {
          this.listSousRubriques[indx] = []
        }
        this.listSousRubriques[indx].push(el[1])
      })
      this.selectRubrique(this.rubrique)
      this.sous_rubrique = this.data['sous_rubrique']
    })
    this.contrat = this.data['contrat']
    this.annee = this.data['annee']
    this.code_dmn_fonc = this.data['code_dmn_fonc']
    this.l_domaine = this.data['l_domaine']
    this.rubrique = this.data['rubrique']
    this.unite = this.data['unite']
    this.valeur = this.data['valeur']
    this.email = this.data['email']
    this.modified_on = this.data['modified_on']
    this.commentaire = this.data['commentaire']
    const _ = moment()
    this.moment.push(_.year())
    for (let i = 1; i < 5; i++) {
      this.moment.push(_.year() + i)
      this.moment.push(_.year() - i)
    }
    this.moment.sort()
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  onConfirmClick() {
    if (!this.new_motif.value) {
      console.error('Error: No Motif')
      this.error = new ApiError()
      this.error.errorMessage =
        'Impossible de mettre à jour le contrat : Motif manquant'
      return
    }
    if (!this.new_comment.nativeElement['value']) {
      console.error('Error: No commentaire')
      this.error = new ApiError()
      this.error.errorMessage =
        'Impossible de mettre à jour le contrat : Commentaire manquant'
      return
    }

    try {
      this.newdata = {
        id: this.data['id'],
        SK: this.data['id'],
        ANNEE: this.new_annee['value'],
        CODE_DMN_FONC: this.new_code_dmn_fonc['value'],
        CODE_RUBRIQUE: this.new_rubrique['value'],
        SOUS_RUBRIQUE: this.new_sous_rubrique['value'],
        L_DOMAINE: this.new_l_domaine['value'] || this.l_domaine,
        VALEUR: this.new_valeur.nativeElement['value'],
        COMMENTAIRE: this.new_comment.nativeElement['value'],
        MOTIF: this.new_motif.value,
      }
      this.spinner = true
    } catch (e) {
      console.error(e)
      this.error = new ApiError()
      this.error.errorMessage =
        'Une erreure est survenue lors de la validation des champs.'
    }

    this.cloudEndpointService
      .updateContract(this.newdata)
      .then((data) => {
        this.spinner = false
        this.dialogRef.close(data)
      })
      .catch((err) => {
        this.spinner = false

        // Change Error attribute chan an error happen
        // This will trigger the display of the poup inside errorModalComponent
        console.error(err)
        this.error = new ApiError()
        if (err.response) {
          if (err.response.data && 'Message' in err.response.data) {
            this.error.errorMessage = err.response.data['Message']
          } else {
            this.error.errorMessage = err.response
          }
        } else {
          this.error.errorMessage = 'Impossible de mettre à jour le contrat'
        }
      })
  }

  public selectCodeDomaine(val: string) {
    this.l_domaine = this.DFPerCodeDF[val]
    this.new_l_domaine['value'] = this.l_domaine
  }

  public selectAnnee() {
    this.booleanAnnee = false
  }

  public selectRubrique(selected: string) {
    const indx = (selected && selected.match(/[0-9A-D]{1,2}/)[0]) || -1
    this.rubriqueSelected = this.listSousRubriques[indx] || [selected]
    this.sous_rubrique = this.rubriqueSelected[0]
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'warningDialog.html',
})
export class WarningDialogComponent {
  public message: string
  public spinner: boolean
  public deleteResponse: any
  error: ApiError
  public confirmSuppression = true
  public yes = 'yes'
  public no = 'no'

  constructor(
    public dialogRef: MatDialogRef<WarningDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InputDataComponent,
    private cloudEndpointService: CloudEndpointService
  ) {
    this.message = 'Etes vous sur de vouloir supprimer cette ligne?'
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  onConfirmClick() {
    this.dialogRef.close()
    this.spinner = true
    this.cloudEndpointService
      .deleteContract(this.data['id'])
      .then((data) => {
        this.spinner = false

        this.deleteResponse = data
        if (this.deleteResponse === 'OK') {
          this.confirmSuppression = false
        } else {
          console.error(data)
        }
      })
      .catch((err) => {
        this.spinner = false

        // Change Error attribute chan an error happen
        // This will trigger the display of the poup inside errorModalComponent
        console.error(err)
        this.error = new ApiError()
        if (err.response) {
          if (err.response.data && 'Message' in err.response.data) {
            this.error.errorMessage = err.response.data['Message']
          } else {
            this.error.errorMessage = err.response
          }
        } else {
          this.error.errorMessage = 'Impossible de supprimer le contrat'
        }
      })
  }
}

export interface PeriodicElement {
  id: string
  sk: string
  contrat: string
  annee: string
  code_dmn_fonc: string
  rubrique: string
  sous_rubrique: string
  l_domaine: string
  email: string
  unite: string
  valeur: string
  motif: string
  modified_on: string
  commentaire: string
}

@Component({
  selector: 'trvs-vac-front-app',
  templateUrl: './inputData.html',
  styleUrls: ['./inputData.css'],
})
export class InputDataComponent implements LoggedInCallback {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator
  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns: string[] = [
    '__',
    'contrat',
    'motif',
    'annee',
    'code_dmn_fonc',
    'rubrique',
    'sous_rubrique',
    'l_domaine',
    'unite',
    'valeur',
    '___',
  ]
  public dataTable: MatTableDataSource<PeriodicElement>
  private contractsData: string[]
  private yearsData: string[]
  private unitsData: string[]

  private displayContractFilterData: string[]
  private displayYearsFilterData: string[]
  private displayUnitsFilterData: string[]

  public selectedContract: string
  public selectedYear: string
  public selectedUnit: string

  public spinner: boolean
  public deleteResponse: any
  error: ApiError
  public motifs: []
  public reponse: string

  public contractControl: FormControl
  public yearControl: FormControl
  public unitControl: FormControl

  public years: Observable<string[]>
  public units: Observable<string[]>
  public contracts: Observable<string[]>
  private loadpending = false

  /**
   * Basic constructor
   */
  constructor(
    private router: Router,
    private userService: UserLoginService,
    private cloudEndpointService: CloudEndpointService,
    public dialog: MatDialog
  ) {
    this.cloudEndpointService.loadLibelle().then((data) => {
      this.motifs = data['MO']
    })
    this.userService.isAuthenticated(this)
    this.displayYearsFilterData = []
    this.displayContractFilterData = []
    this.yearControl = new FormControl()
    this.contractControl = new FormControl()
    this.unitControl = new FormControl()
    this.initDataTable()
  }

  private contract_filter(value: string): string[] {
    const filterValue = value.toLowerCase()

    if (!this.displayContractFilterData) {
      return []
    }

    if (!filterValue) {
      return this.displayContractFilterData
    }
    const ret = this.displayContractFilterData.filter((option) =>
      option.toLowerCase().includes(filterValue)
    )
    return ret
  }

  private year_filter(value: string | number): string[] {
    const filterValue = (value + '').toLowerCase()

    if (!this.displayYearsFilterData) {
      return []
    }

    if (!filterValue) {
      return this.displayYearsFilterData
    }
    const ret = this.displayYearsFilterData.filter((option) =>
      (option + '').toLowerCase().includes(filterValue)
    )
    return ret
  }

  private unit_filter(value: string | number): string[] {
    const filterValue = (value + '').toLowerCase()

    if (!this.displayUnitsFilterData) {
      return []
    }

    if (!filterValue) {
      return this.displayUnitsFilterData
    }
    const ret = this.displayUnitsFilterData.filter((option) =>
      (option + '').toLowerCase().includes(filterValue)
    )
    return ret
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim() // Remove whitespace
    filterValue = filterValue.toLowerCase() // Datasource defaults to lowercase matches
    this.dataTable.filter = filterValue
  }

  private initDataTable() {
    this.spinner = true
    this.contractsData = []
    this.yearsData = []

    const user = JSON.parse(localStorage.getItem('user'))
    // if user has too large of a perimeter, loading contracts will fail
    // hence we deactivate validation
    if (!Constants.largePerimeterUsers.includes(user.email)) {
      this.contractControl.addValidators((control) => {
        if (
          !control.value ||
          this.displayContractFilterData.indexOf(control.value) >= 0
        ) {
          this.loadContracts(
            [this.contractControl.value],
            [this.yearControl.value],
            [this.unitControl]
          )
          return null
        }
        return { value: 'invalide' }
      })
      this.yearControl.addValidators((control) => {
        if (
          !control.value ||
          this.displayYearsFilterData.indexOf(control.value) >= 0
        ) {
          this.loadContracts(
            [this.contractControl.value],
            [this.yearControl.value],
            [this.unitControl]
          )
          return null
        }
        return { value: 'invalide' }
      })
      this.unitControl.addValidators((control) => {
        if (
          !control.value ||
          this.displayUnitsFilterData.indexOf(control.value) >= 0
        ) {
          this.loadContracts(
            [this.contractControl.value],
            [this.yearControl.value],
            [this.unitControl]
          )
          return null
        }
        return { value: 'invalide' }
      })
    } else {
      this.spinner = false
    }
  }

  private loadContracts(contracts, years, units) {
    if (this.loadpending) {
      return
    } else {
      this.loadpending = true
    }
    this.cloudEndpointService
      .loadContracts(contracts, years, units)
      .then((data) => {
        this.spinner = false

        this.contractsData = data['code_contrat']
        this.yearsData = data['annees']
        this.unitsData = data['unites']

        // Robustesse: attention de bien vérifier au préalable que data est bien un tableau (pour ensuite utiliser méthode reduce)
        let tmp = this.contractsData.sort()
        if (tmp !== this.displayContractFilterData) {
          this.displayContractFilterData = tmp

          this.contracts = this.contractControl.valueChanges.pipe(
            startWith(''),
            map((value) => this.contract_filter(value))
          )
        }
        tmp = this.yearsData.sort()
        if (tmp !== this.displayYearsFilterData) {
          this.displayYearsFilterData = tmp
          this.years = this.yearControl.valueChanges.pipe(
            startWith(''),
            map((value) => this.year_filter(value))
          )
        }
        tmp = this.unitsData.sort()
        if (tmp !== this.displayUnitsFilterData) {
          this.displayUnitsFilterData = tmp
          this.units = this.unitControl.valueChanges.pipe(
            startWith(''),
            map((value) => this.unit_filter(value))
          )
        }
        this.loadpending = false
      })
      .catch((err) => {
        this.spinner = false

        // Change Error attribute chan an error happen
        // This will trigger the display of the poup inside errorModalComponent
        console.error(err)
        this.error = new ApiError()
        if (err.response) {
          if (err.response.data && 'Message' in err.response.data) {
            this.error.errorMessage = err.response.data['Message']
          } else {
            this.error.errorMessage = err.response
          }
        } else {
          this.error.errorMessage = 'Impossible de charger la liste'
        }
        this.loadpending = false
      })
  }

  /**
   * Login firewall redirection (will reject user to /home if not logged in).
   */
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/'])
    }
  }

  sendFilter() {
    this.spinner = true

    let contrat = this.contractControl.value
    let annee = this.yearControl.value
    let unit = this.unitControl.value

    if (!annee) {
      annee = 'vide'
    }

    if (!contrat) {
      contrat = 'vide'
    }

    if (!unit) {
      unit = 'vide'
    }

    const that = this
    that.cloudEndpointService
      .getJsonCsvData(contrat, annee, unit)
      .then((data) => {
        this.spinner = false
        const datas: PeriodicElement[] = data

        this.dataTable = new MatTableDataSource(datas)
        this.dataTable.paginator = this.paginator
        this.dataTable.sort = this.sort
      })
      .catch((err) => {
        this.spinner = false

        // Change Error attribute chan an error happen
        // This will trigger the display of the poup inside errorModalComponent
        console.error(err)
        that.error = new ApiError()
        if (err.response) {
          if (err.response.data && 'Message' in err.response.data) {
            that.error.errorMessage = err.response.data['Message']
          } else {
            that.error.errorMessage = err.response
          }
        } else {
          that.error.errorMessage = 'Impossible de charger les contrats'
        }
      })
  }

  updateAt(index: number, newItem: PeriodicElement) {
    const data = this.dataTable.data
    data.splice(
      this.paginator.pageIndex * this.paginator.pageSize + index,
      1,
      newItem
    )

    this.dataTable.data = data
  }

  removeAt(index: number, element) {
    const data = this.dataTable.data
    data.splice(this.paginator.pageIndex * this.paginator.pageSize + index, 1)
    this.dataTable.data = data
    const that = this
    that.cloudEndpointService
      .deleteContract(element.SK.replace('#', '%23').replace(/\//g, '%24'))
      .then((response) => {
        this.deleteResponse = response
        if (this.deleteResponse !== 'OK') {
          console.error(response)
        }
      })
      .catch((err) => {
        this.spinner = false
        // Change Error attribute chan an error happen
        // This will trigger the display of the poup inside errorModalComponent
        console.error(err)
        that.error = new ApiError()
        if (err.response) {
          if (err.response.data && 'Message' in err.response.data) {
            that.error.errorMessage = err.response.data['Message']
          } else {
            that.error.errorMessage = err.response
          }
        } else {
          that.error.errorMessage = 'Impossible de supprimer le contrat'
        }
      })
  }

  openWarning(index: number, element): void {
    const dialogRef = this.dialog.open(WarningDialogComponent, {
      width: '400px',
    })

    dialogRef.afterClosed().subscribe((result) => {
      this.reponse = result
      const i = index
      const e = element
      if (this.reponse === 'yes') {
        this.removeAt(i, e)
      }
    })
  }

  openDialog(index: number, element): void {
    const dialogRef = this.dialog.open(UpdateDialogComponent, {
      width: '600px',
      data: {
        id: element.sk,
        contrat: element.contrat,
        annee: element.annee,
        code_dmn_fonc: element.code_dmn_fonc,
        rubrique: element.rubrique,
        sous_rubrique: element.sous_rubrique,
        l_domaine: element.l_domaine,
        unite: element.unite,
        valeur: element.valeur,
        email: element.email,
        modified_on: element.modified_on.replace(' ', 'T').match(/[0-9-]*/),
        commentaire: element.commentaire,
        motif: element.motif,
      },
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (!result || (result && !result['id'])) {
        return
      }
      const i = index
      const e = element
      this.reponse = result
      const updateItem: PeriodicElement = {
        id: element.sk,
        sk: element.sk,
        contrat: element.contrat,
        annee: this.reponse['ANNEE'],
        code_dmn_fonc: this.reponse['CODE_DMN_FONC'],
        rubrique: this.reponse['CODE_RUBRIQUE'],
        sous_rubrique: this.reponse['SOUS_RUBRIQUE'],
        l_domaine: this.reponse['L_DOMAINE'],
        valeur: this.reponse['VALEUR'],
        unite: element.unite,
        email: this.reponse['EMAIL'],
        modified_on: this.reponse['MODIFIED_ON'],
        commentaire: this.reponse['COMMENTAIRE'],
        motif: this.reponse['MOTIF'],
      }

      this.updateAt(i, updateItem)
    })
  }
}
