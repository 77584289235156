<div class="modal fade" tabindex="-1" role="dialog" id="infoModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content"></div>
    <div class="alert alert-success">
      <div class="modal-header">
        <button
          type="button"
          class="close"
          id="closemod"
          (click)="closeModal()"
          data-dismiss="modal"
          aria-label="Fermer"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <h4 style="text-align: center" class="modal-title">Information</h4>
      </div>
      <div class="modal-body" *ngIf="info">
        <p>{{ info.message }}</p>
      </div>
    </div>

    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
