import { Injectable, Injector, NgZone } from '@angular/core'
import { Router } from '@angular/router'
import { Auth, Cache } from 'aws-amplify'

export interface LoggedInCallback {
  isLoggedIn(message: string, loggedIn: boolean): void
}
@Injectable()
export class UserLoginService {
  private onAmplifyLoginSuccess = () => {
    const routerService = this.injector.get(Router)
    const ngZone = this.injector.get(NgZone)
    ngZone.run(() => {
      routerService.navigate(['/secureHome'], { skipLocationChange: true })
    })
  }

  onLoginSuccess = (googleUser: any) => {
    const token = googleUser.credential
    if (!token) {
      googleUser.getIdTokenResult().then((tk) => {
        this.onLoginSuccess({ credential: tk.token })
      })
      return
    }
    const parsed_token = JSON.parse(
      decodeURIComponent(
        atob(token.split('.')[1].replace(/-/g, '+').replace(/_/g, '/'))
          .split('')
          .map(function (c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
          })
          .join('')
      )
    )
    const id_token = token
    const expires_at = parsed_token.exp
    const user = {
      email: parsed_token.email,
      name: parsed_token.name,
    }
    localStorage.setItem('user', JSON.stringify(user))
    // Initiate federated sign-in with Google identity provider
    Cache.setItem('federatedInfo', { token: id_token })
    Auth.federatedSignIn(
      (parsed_token.iss as string).substr(8),
      {
        token: id_token,
        expires_at,
      },
      user
    ).then(
      () => this.onAmplifyLoginSuccess(),
      (error) => {
        console.error('amplify error')
        console.error(error)
      }
    )
  }

  onLoginError = (error) => {
    console.error('Login error')
    console.error(error)
  }

  onLogoutSuccess = () => {
    // By doing this, we are revoking all the auth tokens
    // which means the user is signed out from all the devices
    // Note: although the tokens are revoked, the AWS credentials will remain valid until they expire (which by default is 1 hour)
    Auth.signOut({ global: true })
      .then((data) => {
        this.router.navigate(['/'])
      })
      .catch(this.onLogoutError)
  }

  onLogoutError = (error) => {
    console.error('Logout error')
    console.error(error)
  }

  isAuthenticated(callback: LoggedInCallback) {
    if (callback == null) {
      throw new Error(
        'UserLoginService: Callback in isAuthenticated() cannot be null'
      )
    }
    try {
      Cache.getItem('federatedInfo')
    } catch {
      callback.isLoggedIn('No token', false)
      return
    }
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user && user.email && user.token) {
          callback.isLoggedIn('Current user found', true)
        } else {
          callback.isLoggedIn('User not found', false)
        }
      })
      .catch((x) => callback.isLoggedIn(x, false))
    return false
  }

  constructor(private router: Router, private injector: Injector) {}
}
