import { Injectable } from '@angular/core';

@Injectable()
export class UtilsService {

    copyToClipboard(textToCopy: string) {
        const copyBlock = document.createElement('textarea');
        copyBlock.value = textToCopy;
        copyBlock.setAttribute('readonly', '');
        copyBlock.style.position = 'absolute';
        copyBlock.style.left = '-9999px';
        document.body.appendChild(copyBlock);
        copyBlock.select();
        document.execCommand('copy');
        document.body.removeChild(copyBlock);
    }

    isCopyToClipboardSupported(): boolean {
        return document.queryCommandSupported('copy');
    }

    constructor() {
    }
}
