import { environment } from '../../environments/environment';
import { Injectable } from '@angular/core';
import Amplify, { API } from 'aws-amplify';

@Injectable()
export class AwsService {

    constructor() {
    }

    initAmplify() {
        Amplify.configure({
            Auth: {
                identityPoolId: environment.identityPoolId,
                region: environment.globalRegion,
                identityPoolRegion: environment.identityRegion,
                mandatorySignIn: true,
            },
            API: {
                endpoints: [
                    {
                        name: 'vac-api',
                        endpoint: environment.apiEndpoint,
                        region: environment.apiRegion
                    }
                ]
            }
        });
    }

}
