<div class="modal fade" tabindex="-1" role="dialog" id="errorModal">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 style="text-align: center; color: crimson" class="modal-title">
          Erreur
        </h4>
      </div>
      <div class="modal-body" *ngIf="error">
        <h4
          style="
            text-align: center;
            color: rgb(20, 100, 220);
            word-break: break-word;
          "
        >
          {{ error.errorMessage }}
        </h4>
      </div>
      <div class="modal-footer">
        <button
          type="button"
          id="closmod"
          (click)="closeModal()"
          class="btn btn-default"
          data-dismiss="modal"
        >
          Fermer
        </button>
      </div>
    </div>
    <!-- /.modal-content -->
  </div>
  <!-- /.modal-dialog -->
</div>
<!-- /.modal -->
