import { Component, OnInit } from '@angular/core'
import { Router } from '@angular/router'
import {
  LoggedInCallback,
  UserLoginService,
} from '../service/user-login.service'

const { version: appVersion } = require('../../../package.json')

@Component({
  selector: 'trvs-vac-front-app',
  templateUrl: './secureHomeLanding.html',
})
export class SecureHomeLandingComponent {
  public userName = ''
  public email = ''
  public appVersion

  constructor(public userService: UserLoginService) {
    this.appVersion = appVersion
    this.initPageData()
  }

  private initPageData() {
    const user = JSON.parse(localStorage.getItem('user'))
    this.userName = user.name
    this.email = user.email
  }
}

@Component({
  selector: 'trvs-vac-front-app',
  templateUrl: './secureHome.html',
})
export class SecureHomeComponent implements OnInit, LoggedInCallback {
  navLinks: any[]
  activeLinkIndex = -1
  constructor(public router: Router, public userService: UserLoginService) {
    this.userService.isAuthenticated(this)
    this.navLinks = [
      {
        label: 'Import CEP',
        link: '/secureHome/csvImport',
        index: 0,
      },
      {
        label: 'MAJ détail CEP',
        link: '/secureHome/inputData',
        index: 1,
      },
      {
        label: 'MAJ infos CEP',
        link: '/secureHome/globalCep',
        index: 2,
      },
      {
        label: 'Import Document',
        link: '/secureHome/docImport',
        index: 3,
      },
      {
        label: 'Voir Documents',
        link: '/secureHome/cepDoc',
        index: 4,
      },
    ]
  }

  ngOnInit(): void {
    this.router.events.subscribe((res) => {
      this.activeLinkIndex = this.navLinks.indexOf(
        this.navLinks.find((tab) => tab.link === '.' + this.router.url)
      )
    })
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/'])
    }
  }
}
