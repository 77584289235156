<div class="row">
  <mat-card class="mat-card">
    <mat-accordion>
      <div style="display: block; width: 100%">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Filtrer par Code Contrat et/ou Année <b>et</b>(optionnel) Unité
            </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-form-field
            style="width: 30%; margin-right: 3%; display: inline-block"
          >
            <mat-label>Contrat</mat-label>
            <input
              type="text"
              aria-label="contract"
              value=""
              matInput
              name="contract"
              ngDefaultControl
              [formControl]="contractControl"
              [matAutocomplete]="contractAC"
              #contract
              [(ngModel)]="selectedContract"
            />
            <button
              mat-button
              *ngIf="selectedContract"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="selectedContract=''"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #contractAC>
              <mat-option
                *ngFor="let element of contracts | async"
                [value]="element"
              >
                {{element}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field
            style="width: 30%; margin-left: 3%; display: inline-block"
          >
            <mat-label>Année</mat-label>
            <input
              type="text"
              year
              matInput
              name="year"
              value=""
              ngDefaultControl
              [formControl]="yearControl"
              [matAutocomplete]="yearAC"
              #year
              [(ngModel)]="selectedYear"
            /><button
              mat-button
              *ngIf="selectedYear"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="selectedYear=''"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #yearAC>
              <mat-option
                *ngFor="let element of years | async"
                [value]="element"
              >
                {{element}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field
            style="width: 30%; margin-left: 3%; display: inline-block"
          >
            <mat-label>Unité</mat-label>
            <input
              type="text"
              unit
              matInput
              name="unit"
              value=""
              ngDefaultControl
              [formControl]="unitControl"
              [matAutocomplete]="unitAC"
              #unit
              [(ngModel)]="selectedUnit"
            /><button
              mat-button
              *ngIf="selectedUnit"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="selectedUnit=''"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #unitAC>
              <mat-option
                *ngFor="let element of units | async"
                [value]="element"
              >
                {{element}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <button
            style="display: block; margin: auto"
            mat-raised-button
            color="primary"
            (click)="sendFilter()"
          >
            Valider
          </button>
        </mat-expansion-panel>
      </div>
    </mat-accordion>

    <mat-progress-bar *ngIf="spinner" mode="indeterminate"></mat-progress-bar>

    <mat-table class="mat-table" [dataSource]="dataTable" matSort>
      <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->

      <ng-container matColumnDef="__">
        <mat-header-cell
          style="flex: 0 0 80px"
          *matHeaderCellDef
          mat-sort-header
        >
        </mat-header-cell>
        <mat-cell
          style="flex: 0 0 100px"
          *matCellDef="let element; let i = index;"
        >
          <button
            style="box-shadow: none"
            mat-mini-fab
            color="warn"
            (click)="openWarning(i,element)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <!-- Position Column -->
      <ng-container matColumnDef="contrat">
        <mat-header-cell
          style="flex: 0 0 90px"
          *matHeaderCellDef
          mat-sort-header
        >
          Code Contrat
        </mat-header-cell>
        <mat-cell style="flex: 0 0 90px" *matCellDef="let element">
          {{element.contrat}}
        </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="annee">
        <mat-header-cell
          style="flex: 0 0 90px"
          *matHeaderCellDef
          mat-sort-header
        >
          Année
        </mat-header-cell>
        <mat-cell style="flex: 0 0 90px" *matCellDef="let element">
          {{element.annee}}
        </mat-cell>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="code_dmn_fonc">
        <mat-header-cell
          style="flex: 0 0 100px"
          *matHeaderCellDef
          mat-sort-header
        >
          Code DF
        </mat-header-cell>
        <mat-cell style="flex: 0 0 100px" *matCellDef="let element">
          {{element.code_dmn_fonc}}
        </mat-cell>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="rubrique">
        <mat-header-cell
          style="flex: 0 0 260px"
          *matHeaderCellDef
          mat-sort-header
        >
          Rubrique
        </mat-header-cell>
        <mat-cell style="flex: 0 0 260px" *matCellDef="let element">
          {{element.rubrique}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="sous_rubrique">
        <mat-header-cell
          style="flex: 0 0 260px"
          *matHeaderCellDef
          mat-sort-header
        >
          Sous rubrique
        </mat-header-cell>
        <mat-cell style="flex: 0 0 260px" *matCellDef="let element">
          {{element.sous_rubrique}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="l_domaine">
        <mat-header-cell
          style="flex: 0 0 130px"
          *matHeaderCellDef
          mat-sort-header
        >
          Libellé DF
        </mat-header-cell>
        <mat-cell style="flex: 0 0 130px" *matCellDef="let element">
          {{element.l_domaine}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="unite">
        <mat-header-cell
          style="flex: 0 0 80px"
          *matHeaderCellDef
          mat-sort-header
        >
          Unité
        </mat-header-cell>
        <mat-cell style="flex: 0 0 80px" *matCellDef="let element">
          {{element.unite}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="valeur">
        <mat-header-cell
          style="flex: 0 0 80px"
          *matHeaderCellDef
          mat-sort-header
        >
          Montant
        </mat-header-cell>
        <mat-cell style="flex: 0 0 80px" *matCellDef="let element">
          {{element.valeur}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="motif">
        <mat-header-cell
          style="flex: 0 0 80px"
          *matHeaderCellDef
          mat-sort-header
        >
          Motif
        </mat-header-cell>
        <mat-cell style="flex: 0 0 90px" *matCellDef="let element">
          {{motifs[element.motif - 1][1]}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="___">
        <mat-header-cell
          style="flex: 0 0 80px"
          *matHeaderCellDef
          mat-sort-header
        >
        </mat-header-cell>
        <mat-cell
          style="flex: 0 0 80px"
          *matCellDef="let element;let i = index;"
          ><button
            style="box-shadow: none"
            mat-mini-fab
            color="primary"
            (click)="openDialog(i,element)"
          >
            <mat-icon>create</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
  </mat-card>

  <trvs-vac-error-modal [error]="error"></trvs-vac-error-modal>
</div>
