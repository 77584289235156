import { Component, OnInit } from '@angular/core'
import { FormBuilder } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Papa } from 'ngx-papaparse'
import { ApiError } from '../../classes/apiError'
import { InfoMessage } from '../../classes/infoMessage'
import { CloudEndpointService } from '../../service/cloud-endpoint.service'
import {
  LoggedInCallback,
  UserLoginService,
} from '../../service/user-login.service'

@Component({
  selector: 'trvs-vac-front-app',
  templateUrl: './help.html',
})
export class HelpComponent implements OnInit, LoggedInCallback {
  pdfSrc = '/assets/doc/release-note-16-VAC.pdf'
  img = undefined
  // Loading lock inside form
  loading = false

  // API error
  error: ApiError

  // Info message when upoad finished
  info: InfoMessage

  /**
   * Basic constructor
   */
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private userService: UserLoginService,
    private cloudEndpointService: CloudEndpointService,
    private papa: Papa,
    private formBuilder: FormBuilder
  ) {
    this.userService.isAuthenticated(this)
  }

  ngOnInit() {}

  openDoc(doc) {
    this.pdfSrc = doc
    this.img = undefined
  }

  /**
   * Login firewall redirection (will reject user to /home if not logged in).
   */
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/'])
    }
  }
}
