import { HttpClient } from '@angular/common/http'
import { Component, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { ApiError } from '../../classes/apiError'
import { CloudEndpointService } from '../../service/cloud-endpoint.service'
import {
  LoggedInCallback,
  UserLoginService,
} from '../../service/user-login.service'
import { UtilsService } from '../../service/utils.service'
import { environment } from '../../../environments/environment'
import { Constants } from '../../global/Constants'

export interface PeriodicElement {
  code_contrat: string
  fichier: string
  date_import: string
  type_doc: string
  date_effet_doc: string
  email: string
  url: string
}

@Component({
  selector: 'trvs-vac-front-app',
  templateUrl: './cepDoc.html',
  styleUrls: ['./cepDoc.css'],
})
export class CepDocComponent implements LoggedInCallback {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator
  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns: string[] = [
    '__',
    'code_contrat',
    'type_doc',
    'date_effet_doc',
    'date_import',
    'fichier',
    'url',
  ]
  public dataSource: any
  public dataTable: MatTableDataSource<PeriodicElement>
  public contractsData: string[]
  public displayContractFilterData: string[]
  public selectedContract: string
  public spinner: boolean
  public deleteResponse: any
  error: ApiError
  public reponse: string
  public contractControl: FormControl
  public contracts: Observable<string[]>
  public listType: []

  /**
   * Basic constructor
   */
  constructor(
    private router: Router,
    private userService: UserLoginService,
    private cloudEndpointService: CloudEndpointService,
    private http: HttpClient,
    private utils: UtilsService,
    public dialog: MatDialog
  ) {
    this.cloudEndpointService.loadLibelle().then((data) => {
      this.listType = data['DC'] || [[1, 'vide']]
    })
    this.userService.isAuthenticated(this)
    this.displayContractFilterData = []
    this.contractControl = new FormControl()
    this.initDataTable()
  }

  private contract_filter(value: string): string[] {
    const filterValue = value.toLowerCase()

    if (!this.displayContractFilterData) {
      return []
    }

    if (!filterValue) {
      return this.displayContractFilterData
    }
    const ret = this.displayContractFilterData.filter((option) =>
      option.toLowerCase().includes(filterValue)
    )
    return ret
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim() // Remove whitespace
    filterValue = filterValue.toLowerCase() // Datasource defaults to lowercase matches
    this.dataTable.filter = filterValue
  }

  private initDataTable() {
    this.spinner = true
    this.contractsData = []

    const user = JSON.parse(localStorage.getItem('user'))
    // if user has too large of a perimeter, loading contracts will fail
    // hence we deactivate validation
    if (!Constants.largePerimeterUsers.includes(user.email)) {
      this.contractControl.addValidators((control) => {
        if (
          !control.value ||
          this.displayContractFilterData.indexOf(control.value) >= 0
        ) {
          this.loadContracts([this.contractControl.value])
          return null
        }
        return { value: 'invalide' }
      })
      this.loadContracts([this.contractControl.value])
    } else {
      this.spinner = false
    }
  }

  private loadContracts(contracts) {
    this.cloudEndpointService
      .loadContracts(contracts)
      .then((data) => {
        this.spinner = false
        this.contractsData = data['code_contrat']

        // Robustesse: attention de bien vérifier au préalable que data est bien un tableau (pour ensuite utiliser méthode reduce)
        const tmp = this.contractsData.sort()
        if (tmp !== this.displayContractFilterData) {
          this.displayContractFilterData = tmp

          this.contracts = this.contractControl.valueChanges.pipe(
            startWith(''),
            map((value) => this.contract_filter(value))
          )
        }
      })
      .catch((err) => {
        this.spinner = false

        // Change Error attribute chan an error happen
        // This will trigger the display of the poup inside errorModalComponent
        console.error(err)
        this.error = new ApiError()
        if (err.response) {
          if (err.response.data && 'Message' in err.response.data) {
            this.error.errorMessage = err.response.data['Message']
          } else {
            this.error.errorMessage = err.response
          }
        } else {
          this.error.errorMessage = 'Impossible de charger la liste'
        }
      })
  }

  /**
   * Login firewall redirection (will reject user to /home if not logged in).
   */
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/'])
    }
  }

  sendFilter() {
    this.spinner = true

    let code_contrat = this.contractControl.value

    if (!code_contrat) {
      code_contrat = 'vide'
    }

    const that = this
    that.cloudEndpointService
      .getDocumentsJsonCsvData(code_contrat)
      .then((data) => {
        this.spinner = false
        const datas: PeriodicElement[] = data.map((el) => ({
          code_contrat: el.PK.split('#')[1],
          fichier: el.FICHIER,
          date_import: el.DATE_IMPORT,
          type_doc: el.TYPE_DOC,
          date_effet_doc: el.DATE_EFFET_DOC,
          url:
            environment.apiEndpoint +
            '/docs/' +
            el.PK.split('#')[1] +
            '/' +
            el.SK.split('#')[1],
          email: el.EMAIL,
        }))

        this.dataSource = data
        this.dataTable = new MatTableDataSource(datas)
        this.dataTable.paginator = this.paginator
        this.dataTable.sort = this.sort
      })
      .catch((err) => {
        this.spinner = false

        // Change Error attribute chan an error happen
        // This will trigger the display of the poup inside errorModalComponent
        console.error(err)
        that.error = new ApiError()
        if (err.response) {
          if (err.response.data && 'Message' in err.response.data) {
            that.error.errorMessage = err.response.data['Message']
          } else {
            that.error.errorMessage = err.response
          }
        } else {
          that.error.errorMessage = 'Impossible de charger les documents'
        }
      })
  }
}
