<div class="row">
  <mat-card class="mat-card">
    <mat-accordion>
      <!-- <button style="background-color: rgb(150, 148, 148); margin-left: 1790px" mat-fab>
                        <mat-icon>add</mat-icon>
                    </button>
                    <br/>
                    <br/>-->
      <div style="display: block; width: 100%">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Filtrer par Code Contrat et/ou Année
            </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-form-field
            style="width: 40%; margin-right: 10%; display: inline-block"
          >
            <mat-label>Contrat</mat-label>
            <input
              type="text"
              aria-label="contract"
              value=""
              matInput
              name="contract"
              ngDefaultControl
              [formControl]="contractControl"
              [matAutocomplete]="contractAC"
              #contract
              [(ngModel)]="selectedContract"
            />
            <button
              mat-button
              *ngIf="selectedContract"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="selectedContract=''"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #contractAC>
              <mat-option
                *ngFor="let element of contracts | async"
                [value]="element"
              >
                {{element}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <mat-form-field
            style="width: 40%; margin-left: 10%; display: inline-block"
          >
            <mat-label>Année</mat-label>
            <input
              type="text"
              year
              matInput
              name="year"
              value=""
              ngDefaultControl
              [formControl]="yearControl"
              [matAutocomplete]="yearAC"
              #year
              [(ngModel)]="selectedYear"
            /><button
              mat-button
              *ngIf="selectedYear"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="selectedYear=''"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #yearAC>
              <mat-option
                *ngFor="let element of years | async"
                [value]="element"
              >
                {{element}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <button
            style="display: block; margin: auto"
            mat-raised-button
            color="primary"
            (click)="sendFilter()"
          >
            Valider
          </button>
        </mat-expansion-panel>
      </div>
    </mat-accordion>

    <mat-progress-bar *ngIf="spinner" mode="indeterminate"></mat-progress-bar>

    <mat-table class="mat-table" [dataSource]="dataTable" matSort>
      <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->
      <!-- Position Column -->

      <ng-container matColumnDef="__">
        <mat-header-cell
          style="flex: 0 0 80px"
          *matHeaderCellDef
          mat-sort-header
        >
        </mat-header-cell>
        <mat-cell
          style="flex: 0 0 80px"
          *matCellDef="let element; let i = index;"
        >
          <button
            style="box-shadow: none"
            mat-mini-fab
            color="warn"
            (click)="openWarning(i,element)"
          >
            <mat-icon>delete</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="contrat">
        <mat-header-cell
          style="flex: 0 0 80px"
          *matHeaderCellDef
          mat-sort-header
        >
          Code Contrat
        </mat-header-cell>
        <mat-cell style="flex: 0 0 80px" *matCellDef="let element">
          {{element.contrat.substr(0, 5)}}
        </mat-cell>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="annee">
        <mat-header-cell
          style="flex: 0 0 80px"
          *matHeaderCellDef
          mat-sort-header
        >
          Année
        </mat-header-cell>
        <mat-cell style="flex: 0 0 80px" *matCellDef="let element">
          {{element.annee}}
        </mat-cell>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="date_import">
        <mat-header-cell
          style="flex: 0 0 100px"
          *matHeaderCellDef
          mat-sort-header
        >
          Date d'import
        </mat-header-cell>
        <mat-cell style="flex: 0 0 100px" *matCellDef="let element">
          {{element.date_import}}
        </mat-cell>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="motif">
        <mat-header-cell
          style="flex: 0 0 160px"
          *matHeaderCellDef
          mat-sort-header
        >
          Motif
        </mat-header-cell>
        <mat-cell style="flex: 0 0 160px" *matCellDef="let element">
          {{listMotif[element.motif -1][1]}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="commentaire">
        <mat-header-cell
          style="flex: 0 0 300px"
          *matHeaderCellDef
          mat-sort-header
        >
          Commentaire
        </mat-header-cell>
        <mat-cell style="flex: 0 0 300px" *matCellDef="let element">
          {{element.commentaire}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="qualite">
        <mat-header-cell
          style="flex: 0 0 160px"
          *matHeaderCellDef
          mat-sort-header
        >
          Qualite
        </mat-header-cell>
        <mat-cell style="flex: 0 0 160px" *matCellDef="let element">
          {{listQualite[element.qualite - 1][1]}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="email">
        <mat-header-cell
          style="flex: 0 0 220px"
          *matHeaderCellDef
          mat-sort-header
        >
          Importé par
        </mat-header-cell>
        <mat-cell style="flex: 0 0 220px" *matCellDef="let element">
          {{element.email}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date_valeur">
        <mat-header-cell
          style="flex: 0 0 120px"
          *matHeaderCellDef
          mat-sort-header
        >
          Date d’effet du chiffrage initial
        </mat-header-cell>
        <mat-cell style="flex: 0 0 120px" *matCellDef="let element">
          {{element.date_valeur}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="___">
        <mat-header-cell
          style="flex: 0 0 80px"
          *matHeaderCellDef
          mat-sort-header
        >
        </mat-header-cell>
        <mat-cell
          style="flex: 0 0 80px"
          *matCellDef="let element;let i = index;"
          ><button
            style="box-shadow: none"
            mat-mini-fab
            color="primary"
            (click)="openDialog(i,element)"
          >
            <mat-icon>create</mat-icon>
          </button>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
  </mat-card>

  <trvs-vac-error-modal [error]="error"></trvs-vac-error-modal>
</div>
