import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'
import {
  MatDialog,
  MatDialogRef,
  MAT_DIALOG_DATA,
} from '@angular/material/dialog'
import { MatPaginator } from '@angular/material/paginator'
import { MatSelect } from '@angular/material/select'
import { MatSort } from '@angular/material/sort'
import { MatTableDataSource } from '@angular/material/table'
import { Router } from '@angular/router'
import * as moment from 'moment'
import { Observable } from 'rxjs'
import { map, startWith } from 'rxjs/operators'
import { ApiError } from '../../classes/apiError'
import { CloudEndpointService } from '../../service/cloud-endpoint.service'
import {
  LoggedInCallback,
  UserLoginService,
} from '../../service/user-login.service'
import { Constants } from '../../global/Constants'

@Component({
  selector: 'update-item',
  templateUrl: 'updateItem.html',
})
export class UpdateCepComponent implements OnInit {
  @ViewChild('quali', { static: false }) new_qualite: ElementRef
  @ViewChild('dtvaleur', { static: false }) new_date_valeur: ElementRef
  @ViewChild('comment', { static: false }) new_comment: ElementRef
  @ViewChild('mot', { static: false }) new_motif: MatSelect
  public test = true
  public newdata = {}

  public message: any
  public spinner: any
  public deleteResponse: any
  error: ApiError
  public contrat: string
  public annee: string
  public motif: number
  public fichier: string
  public commentaire: string
  public commentaire1: string
  public date_import: string
  public qualite: number
  public date_valeur: string
  public email: string
  public listMotif = []
  public listQualite = []
  public checked = true
  public moment = []
  public updateResponse: any
  public booleanAnnee = true

  constructor(
    public dialogRef: MatDialogRef<UpdateCepComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GlobalCepComponent,
    private cloudEndpointService: CloudEndpointService
  ) {}

  date: Date

  ngOnInit(): void {
    this.cloudEndpointService.loadLibelle().then((data) => {
      this.listMotif = data['MO']
      this.listQualite = data['QA']
    })
    this.contrat = this.data['contrat']
    this.annee = this.data['annee']
    this.motif = this.data['motif']
    this.date_import = this.data['date_import']
    this.fichier = this.data['fichier']
    this.commentaire = this.data['commentaire']
    this.qualite = this.data['qualite']
    this.date_valeur = this.data['date_valeur']
    this.email = this.data['email']
    const _ = moment()
    this.moment.push(_.year())
    for (let i = 1; i < 5; i++) {
      this.moment.push(_.year() + i)
      this.moment.push(_.year() - i)
    }
    this.moment.sort()
  }

  onNoClick(): void {
    this.dialogRef.close()
  }

  onConfirmClick() {
    if (!this.new_motif.value) {
      console.error('Error: No Motif')
      this.error = new ApiError()
      this.error.errorMessage = 'Le motif est requis et manquant'
      return
    }
    if (!this.new_comment.nativeElement['value']) {
      console.error('Error: No commentaire')
      this.error = new ApiError()
      this.error.errorMessage = 'Un commentaire détaillé est requis et manquant'
      return
    }

    this.newdata = {
      id: this.data['id'],
      PK: this.data['PK'],
      SK: 'year#' + this.annee,
      COMMENTAIRE: this.new_comment.nativeElement['value'],
      MOTIF: this.new_motif.value,
      QUALITE: this.new_qualite['value'],
      DATE_VALEUR: this.new_date_valeur.nativeElement['value']
        .split('-')
        .reverse()
        .join('/'),
      DATE_IMPORT: this.date_import,
      FICHIER: this.fichier,
      EMAIL: this.email,
    }
    this.spinner = true

    this.cloudEndpointService
      .UpdateCepComponent(this.newdata)
      .then((data) => {
        this.spinner = false
        this.dialogRef.close(data)
      })
      .catch((err) => {
        this.spinner = false

        // Change Error attribute chan an error happen
        // This will trigger the display of the poup inside errorModalComponent
        console.error(err)
        this.error = new ApiError()
        if (err.response) {
          if (err.response.data && 'Message' in err.response.data) {
            this.error.errorMessage = err.response.data['Message']
          } else {
            this.error.errorMessage = err.response
          }
        } else {
          this.error.errorMessage = 'Impossible de mettre à jour le contrat'
        }
      })
  }
}

@Component({
  selector: 'dialog-overview-example-dialog',
  templateUrl: 'warningDialog.html',
})
export class WarnCepDeletionComponent {
  public message: any
  public spinner: any
  public deleteResponse: any
  error: ApiError
  public confirmSuppression = true
  public yes = 'yes'
  public no = 'no'

  constructor(
    public dialogRef: MatDialogRef<WarnCepDeletionComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GlobalCepComponent
  ) {
    this.message =
      'Attention : vous allez supprimer cette ligne ainsi que TOUTES ' +
      'les lignes de détail du CEP pour ce couple Contrat / Année sélectionné.' +
      '\n\tÊtes vous certain( e ) de vouloir continuer ? '
  }

  onNoClick(): void {
    this.dialogRef.close()
  }
}

export interface PeriodicElement {
  id: string
  contrat: string
  annee: string
  motif: number
  fichier: string
  commentaire: string
  date_import: string
  qualite: number
  date_valeur: string
}

@Component({
  selector: 'trvs-vac-front-app',
  templateUrl: './globalCep.html',
  styleUrls: ['./globalCep.css'],
})
export class GlobalCepComponent implements LoggedInCallback {
  @ViewChild(MatPaginator, { static: false }) paginator: MatPaginator
  @ViewChild(MatSort, { static: false }) sort: MatSort

  displayedColumns: string[] = [
    '__',
    'contrat',
    'annee',
    'qualite',
    'motif',
    'commentaire',
    'date_valeur',
    'date_import',
    'email',
    '___',
  ]
  public dataSource: any
  public dataTable: MatTableDataSource<PeriodicElement>
  public contractsData: string[]
  public yearsData: string[]
  public displayContractFilterData: string[]
  public displayYearsFilterData: string[]
  public selectedContract: string
  public selectedYear: string
  public booleanContrat: boolean
  public booleanYear: boolean
  public spinner: boolean
  public deleteResponse: any
  error: ApiError
  public reponse: string
  public contractControl: FormControl
  public yearControl: FormControl
  public years: Observable<string[]>
  public contracts: Observable<string[]>
  public listMotif: []
  public listQualite: []
  private loadpending = false

  /**
   * Basic constructor
   */
  constructor(
    private router: Router,
    private userService: UserLoginService,
    private cloudEndpointService: CloudEndpointService,
    public dialog: MatDialog
  ) {
    this.cloudEndpointService.loadLibelle().then((data) => {
      this.listMotif = data['MO']
      this.listQualite = data['QA']
    })
    this.userService.isAuthenticated(this)
    this.displayYearsFilterData = []
    this.displayContractFilterData = []
    this.booleanContrat = false
    this.booleanYear = false
    this.yearControl = new FormControl()
    this.contractControl = new FormControl()
    this.initDataTable()
  }

  private contract_filter(value: string): string[] {
    const filterValue = value.toLowerCase()

    if (!this.displayContractFilterData) {
      return []
    }

    if (!filterValue) {
      return this.displayContractFilterData
    }
    const ret = this.displayContractFilterData.filter((option) =>
      option.toLowerCase().includes(filterValue)
    )
    return ret
  }

  private year_filter(value: string | number): string[] {
    const filterValue = (value + '').toLowerCase()

    if (!this.displayYearsFilterData) {
      return []
    }

    if (!filterValue) {
      return this.displayYearsFilterData
    }
    const ret = this.displayYearsFilterData.filter((option) =>
      (option + '').toLowerCase().includes(filterValue)
    )
    return ret
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.trim() // Remove whitespace
    filterValue = filterValue.toLowerCase() // Datasource defaults to lowercase matches
    this.dataTable.filter = filterValue
  }

  booleanContratTrue() {
    this.booleanContrat = true
    this.booleanYear = false
  }

  booleanYearTrue() {
    this.booleanContrat = false
    this.booleanYear = true
  }

  private initDataTable() {
    this.spinner = true
    this.contractsData = []
    this.yearsData = []

    const user = JSON.parse(localStorage.getItem('user'))
    // if user has too large of a perimeter, loading contracts will fail
    // hence we deactivate validation
    if (!Constants.largePerimeterUsers.includes(user.email)) {
      this.contractControl.addValidators((control) => {
        if (
          !control.value ||
          this.displayContractFilterData.indexOf(control.value) >= 0
        ) {
          this.loadContracts(
            [this.contractControl.value],
            [this.yearControl.value]
          )
          return null
        }
        return { value: 'invalide' }
      })
      this.yearControl.addValidators((control) => {
        if (
          !control.value ||
          this.displayYearsFilterData.indexOf(control.value) >= 0
        ) {
          this.loadContracts(
            [this.contractControl.value],
            [this.yearControl.value]
          )
          return null
        }
        return { value: 'invalide' }
      })
    } else {
      this.spinner = false
    }
  }

  private loadContracts(contracts, years) {
    if (this.loadpending) {
      return
    } else {
      this.loadpending = true
    }
    this.cloudEndpointService
      .loadContracts(contracts, years)
      .then((data) => {
        this.spinner = false
        this.contractsData = data['code_contrat']
        this.yearsData = data['annees']

        // Robustesse: attention de bien vérifier au préalable que data est bien un tableau (pour ensuite utiliser méthode reduce)
        let tmp = this.contractsData.sort()
        if (tmp !== this.displayContractFilterData) {
          this.displayContractFilterData = tmp

          this.contracts = this.contractControl.valueChanges.pipe(
            startWith(''),
            map((value) => this.contract_filter(value))
          )
        }
        tmp = this.yearsData.sort()
        if (tmp !== this.displayYearsFilterData) {
          this.displayYearsFilterData = tmp
          this.years = this.yearControl.valueChanges.pipe(
            startWith(''),
            map((value) => this.year_filter(value))
          )
        }
        this.loadpending = false
      })
      .catch((err) => {
        this.spinner = false

        // Change Error attribute chan an error happen
        // This will trigger the display of the poup inside errorModalComponent
        console.error(err)
        this.error = new ApiError()
        if (err.response) {
          if (err.response.data && 'Message' in err.response.data) {
            this.error.errorMessage = err.response.data['Message']
          } else {
            this.error.errorMessage = err.response
          }
        } else {
          this.error.errorMessage = 'Impossible de charger la liste'
        }
        this.loadpending = false
      })
  }

  /**
   * Login firewall redirection (will reject user to /home if not logged in).
   */
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/'])
    }
  }

  sendFilter() {
    this.spinner = true

    let contrat = this.contractControl.value
    let annee = this.yearControl.value

    if (!annee) {
      annee = 'vide'
    }

    if (!contrat) {
      contrat = 'vide'
    }

    const that = this
    that.cloudEndpointService
      .getCepsJsonCsvData(contrat, annee)
      .then((data) => {
        this.spinner = false
        const datas: PeriodicElement[] = data.map((el) => ({
          id: el.id,
          contrat: el.PK.split('#')[1],
          annee: el.SK.split('#')[1],
          motif: el.MOTIF,
          fichier: el.FICHIER,
          commentaire: el.COMMENTAIRE,
          date_import: el.DATE_IMPORT,
          qualite: el.QUALITE,
          date_valeur: el.DATE_VALEUR,
          email: el.EMAIL,
          PK: el.PK,
        }))

        this.dataSource = data
        this.dataTable = new MatTableDataSource(datas)
        this.dataTable.paginator = this.paginator
        this.dataTable.sort = this.sort
      })
      .catch((err) => {
        this.spinner = false

        // Change Error attribute chan an error happen
        // This will trigger the display of the poup inside errorModalComponent
        console.error(err)
        that.error = new ApiError()
        if (err.response) {
          if (err.response.data && 'Message' in err.response.data) {
            that.error.errorMessage = err.response.data['Message']
          } else {
            that.error.errorMessage = err.response
          }
        } else {
          that.error.errorMessage = 'Impossible de charger les contrats'
        }
      })
  }

  updateAt(index: number, element, newItem) {
    const data = this.dataTable.data
    index = data.indexOf(element)
    data.splice(
      this.paginator.pageIndex * this.paginator.pageSize + index,
      1,
      newItem
    )

    this.dataTable.data = data
  }

  removeAt(index: number, element) {
    const data = this.dataTable.data
    const that = this
    const pk_sk = element.PK.substr(4) + '/' + element.annee
    that.cloudEndpointService
      .deleteCep(pk_sk, element.PK.substr(0, 3))
      .then((ret_data) => {
        this.spinner = false
        index = data.indexOf(element)
        data.splice(
          this.paginator.pageIndex * this.paginator.pageSize + index,
          1
        )
        this.dataTable.data = data
      })
      .catch((err) => {
        this.spinner = false
        // Change Error attribute chan an error happen
        // This will trigger the display of the poup inside errorModalComponent
        console.error(err)
        that.error = new ApiError()
        if (err.response) {
          if (err.response.data && 'Message' in err.response.data) {
            that.error.errorMessage = err.response.data['Message']
          } else {
            that.error.errorMessage = err.response
          }
        } else {
          that.error.errorMessage = 'Impossible de supprimer les contrats'
        }
      })
  }

  openWarning(index: number, element): void {
    const dialogRef = this.dialog.open(WarnCepDeletionComponent, {
      width: '400px',
    })

    dialogRef.afterClosed().subscribe((result) => {
      this.reponse = result
      const i = index
      const e = element
      if (this.reponse === 'yes') {
        this.removeAt(i, e)
      }
    })
  }

  openDialog(index: number, element): void {
    const dialogRef = this.dialog.open(UpdateCepComponent, {
      width: '600px',
      data: {
        id: element.id,
        contrat: element.contrat,
        annee: element.annee,
        motif: element.motif,
        fichier: element.fichier,
        commentaire: element.commentaire,
        date_import: element.date_import,
        qualite: element.qualite,
        date_valeur: element.date_valeur,
        email: element.email,
      },
    })

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return
      }
      const i = index
      const e = element
      let updateItem = {}
      this.reponse = result
      updateItem = {
        id: element.id,
        contrat: element.contrat,
        annee: element.annee,
        motif: this.reponse['MOTIF'],
        fichier: this.reponse['FICHIER'],
        commentaire: this.reponse['COMMENTAIRE'],
        date_import: this.reponse['DATE_IMPORT'],
        date_valeur: this.reponse['DATE_VALEUR'],
        qualite: this.reponse['QUALITE'],
        email: this.reponse['EMAIL'],
      }

      this.updateAt(i, e, updateItem)
    })
  }
}
