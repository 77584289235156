<h1 style="text-align: center; margin: 0" color="primary" mat-dialog-title>
  Mettre à jour
</h1>
<div mat-dialog-content>
  <form class="example-form">
    <mat-form-field class="example-full-width">
      <input
        #code
        style="color: #333"
        aria-disabled
        disabled
        matInput
        placeholder="Code contrat"
        [value]="contrat"
      />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input
        #an
        style="color: #333"
        placeholder="Année"
        aria-disabled
        matInput
        disabled
        [value]="annee"
      />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <mat-select placeholder="Qualite" #quali [value]="qualite.toString()">
        <mat-option *ngFor="let element of listQualite" [value]="element[0]">
          {{element[1]}}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input
        matInput
        #dtvaleur
        placeholder="Date d’effet du chiffrage initial"
        type="date"
        style="color: #333"
        [value]="date_valeur.split('/').reverse().join('-')"
      />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input
        matInput
        #dtimport
        placeholder="Date d'import"
        type="date"
        style="color: #333"
        disabled
        [value]="date_import.split('/').reverse().join('-')"
      />
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input
        matInput
        #file
        placeholder="Fichier d'import"
        style="color: #333"
        disabled
        [value]="fichier"
      />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input
        matInput
        #mail
        placeholder="Dernier Import par"
        type="text"
        style="color: #333"
        disabled
        [value]="email"
      />
    </mat-form-field>

    <mat-form-field class="example-full-width">
      <input
        matInput
        placeholder="Motif (*)"
        type="text"
        style="color: #333"
        disabled
        [value]="listMotif && listMotif[motif - 1] && listMotif[motif - 1][1]"
      />
    </mat-form-field>
    <mat-form-field class="example-full-width">
      <input
        matInput
        placeholder="Dernier Commentaire (*)"
        type="text"
        style="color: #333"
        disabled
        [value]="commentaire"
      />
    </mat-form-field>
  </form>
</div>
<div mat-dialog-actions>
  <mat-form-field class="example-full-width">
    <mat-select placeholder="* Motif requis" #mot>
      <mat-option *ngFor="let element of listMotif" [value]="element[0]">
        {{element[1]}}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field class="example-full-width">
    <input
      matInput
      minlength="10"
      #comment
      placeholder="* Commentaire requis"
      type="text"
      [value]=""
    />
  </mat-form-field>
  <p
    style="color: red; display: inline-block; width: 50%"
    *ngIf="error && error.errorMessage"
  >
    {{error.errorMessage}}
  </p>
  <button
    style="display: inline-block; margin: auto"
    mat-button
    color="warn"
    [mat-dialog-close]="false"
  >
    Retour
  </button>
  <button
    style="display: inline-block; margin: auto"
    mat-button
    color="primary"
    (click)="onConfirmClick()"
    cdkFocusInitial
  >
    Mettre à jour
  </button>
</div>
