<div>
  <div *ngIf="loading">
    <mat-progress-bar mode="indeterminate"></mat-progress-bar>
  </div>
  <mat-card style="text-align: center">
    <div class="row">
      <div>
        <form>
          <h3 style>Importer un fichier</h3>
          <br />
          <input
            style="margin: auto; width: 300px"
            type="file"
            accept=".csv,.xlsx,.xls,.ods,.xlsb"
            id="inputFileForm"
            (change)="onFileChange($event)"
            #fileInput
          /><br />

          <mat-form-field
            style="
              font-weight: normal;
              margin: auto;
              width: 300px;
              display: block;
            "
            class="example-full-width"
          >
            <mat-select
              placeholder="Qualité du CEP (requis)"
              id="qualite"
              #qualite
              required="true"
              (selectionChange)="onQualiteChange($event)"
            >
              <mat-option
                *ngFor="let element of listQualite"
                [value]="element[0]"
              >
                {{element[1]}}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field
            style="
              font-weight: normal;
              margin: auto;
              width: 300px;
              display: block;
            "
            class="example-full-width"
          >
            <mat-select
              placeholder="Motif d'import (requis)"
              id="motif"
              #motif
              required="true"
              (selectionChange)="onMotifChange($event)"
            >
              <mat-option
                *ngFor="let element of listMotif"
                [value]="element[0]"
              >
                {{element[1]}}
              </mat-option> </mat-select
            ><br
          /></mat-form-field>
          <mat-form-field
            style="
              font-weight: normal;
              margin: auto;
              width: 300px;
              display: block;
            "
            class="example-full-width"
          >
            <input
              matInput
              type="text"
              placeholder="Commentaire (requis)"
              id="commentaire"
              #commentaire
              required="true"
              (change)="onCommChange($event)"
              (keyup)="onCommChange($event)" /><br
          /></mat-form-field>
          <mat-form-field
            style="
              font-weight: normal;
              margin: auto;
              width: 300px;
              display: block;
            "
            class="example-full-width"
          >
            <input
              matInput
              type="date"
              placeholder="Date d'effet du chiffrage initial (requis)"
              id="date_valeur"
              #date_valeur
              required="true"
              (change)="onDateValeurChange($event)"
              (keyup)="onDateValeurChange($event)"
          /></mat-form-field>
        </form>
      </div>
      <div class="col-md-6"></div>
      <div class="col-md-2">
        <button
          mat-raised-button
          color="primary"
          type="submit"
          [disabled]="form.invalid || loading"
          (click)="onSubmit()"
        >
          <mat-icon> attach_file</mat-icon> Importer
        </button>
      </div>
      <div class="col-md-1">
        <button
          mat-raised-button
          color="warn"
          type="submit"
          (click)="onCancel()"
        >
          <mat-icon>delete</mat-icon> Annuler
        </button>
      </div>
      <div class="col-md-10"></div>
    </div>

    <hr size="4" />
    <ul style="padding-inline-start: 0px">
      <li
        class="list-group-item alert-danger"
        *ngFor="let item of mess"
        style="margin-top: 0px"
      >
        <mat-icon>warning</mat-icon>
        {{item}}
      </li>
    </ul>
  </mat-card>
</div>
<trvs-vac-error-modal [error]="error"></trvs-vac-error-modal>
<trvs-vac-info-modal [info]="info"></trvs-vac-info-modal>
