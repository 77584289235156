import { Component, ElementRef, OnInit, ViewChild } from '@angular/core'
import { FormBuilder, FormGroup, Validators } from '@angular/forms'
import { MatDialog } from '@angular/material/dialog'
import { Router } from '@angular/router'
import { Papa } from 'ngx-papaparse'
import * as XLSX from 'xlsx'
import { environment } from '../../../environments/environment'
import { ApiError } from '../../classes/apiError'
import { InfoMessage } from '../../classes/infoMessage'
import { CloudEndpointService } from '../../service/cloud-endpoint.service'
import {
  LoggedInCallback,
  UserLoginService,
} from '../../service/user-login.service'

@Component({
  selector: 'trvs-vac-front-app',
  templateUrl: './csvImport.html',
})
export class CsvImportComponent implements OnInit, LoggedInCallback {
  mess: string[] = []
  // Generated form group
  form: FormGroup = null

  // Loading lock inside form
  loading = false

  // API error
  error: ApiError

  // Info message when upload finished
  info: InfoMessage

  // Input form element
  @ViewChild('fileInput', { static: false }) fileInput: ElementRef // File
  @ViewChild('commentaire', { static: false }) commentaire: ElementRef // String
  @ViewChild('motif', { static: false }) motif: ElementRef // Int (Select)
  @ViewChild('qualite', { static: false }) qualite: ElementRef // Int (Select)
  @ViewChild('date_valeur', { static: false }) date_valeur: ElementRef // String (date)

  public listMotif: string[][] = []
  public listQualite: string[][] = []
  public listRubrique: string[][] = []
  public listDmnFonc: string[][] = []

  /**
   * Basic constructor
   */
  constructor(
    private router: Router,
    public dialog: MatDialog,
    private userService: UserLoginService,
    private cloudEndpointService: CloudEndpointService,
    private papa: Papa,
    private formBuilder: FormBuilder
  ) {
    this.userService.isAuthenticated(this)
    this.createForm()
  }

  ngOnInit() {
    this.cloudEndpointService
      .loadLibelle()
      .then((data) => {
        this.listMotif = data['MO'] || ['Vide']
        this.listQualite = data['QA'] || ['Vide']
        this.listRubrique = data['RU'] || ['Vide']
        this.listRubrique.push(['15-0', '15-0-CEP AGE'])
        this.listRubrique.push(['16-0', '16-0-CEP AGE'])
        this.listDmnFonc = data['DF'] || ['Vide']
      })
      .catch((err) => {
        console.error(err)
        this.error = new ApiError()
        if (err.response) {
          if (err.response.data && 'Message' in err.response.data) {
            this.error.errorMessage = err.response.data['Message']
          } else {
            this.error.errorMessage = err.response
          }
        } else {
          this.error.errorMessage =
            "Une erreur s'est produite au moment du chargement des libellés :" +
            err
        }
        this.error.errorObject = err
      })
  }

  /**
   * Login firewall redirection (will reject user to /home if not logged in).
   */
  isLoggedIn(message: string, isLoggedIn: boolean) {
    if (!isLoggedIn) {
      this.router.navigate(['/'])
    }
  }

  private createForm() {
    // Attach a validator to form (basic: if empty = rejected)
    this.form = this.formBuilder.group({
      name: ['', Validators.required],
      fileContent: null,
      commentaire: ['', Validators.required],
      motif: ['', Validators.required],
      qualite: ['', Validators.required],
      date_valeur: ['', Validators.required],
    })
  }

  /**
   * Convert CSV file to JSON, ready to send to API.
   */
  onFileChange = (event: any) => {
    if (event.target.files.length > 0) {
      // Get file element from form
      const file = event.target.files[0]

      if (file.name.indexOf('.csv') <= 0) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const data = e.target.result
          const workbook = XLSX.read(data, { type: 'binary' })
          const content = XLSX.utils.sheet_to_csv(
            workbook.Sheets[workbook.SheetNames[0]],
            {
              FS: ';',
              skipHidden: false,
            }
          )
          this.form.get('fileContent').setValue(content)
        }
        reader.readAsBinaryString(file)
      }

      // Store content inside internal form object
      this.form.get('name').setValue(file.name)
      this.form.get('fileContent').setValue(file)
    }
  }

  onCommChange = (event: any) => {
    const comment = event.target.value

    // Store content inside internal form object
    this.form.get('commentaire').setValue(comment)
  }

  onMotifChange = (event: any) => {
    const motif = event.value

    // Store content inside internal form object
    this.form.get('motif').setValue(motif)
  }

  onQualiteChange = (event: any) => {
    const qualite = event.value

    // Store content inside internal form object
    this.form.get('qualite').setValue(qualite)
  }

  onDateValeurChange = (event: any) => {
    const date_valeur = event.target.value

    // Store content inside internal form object
    this.form.get('date_valeur').setValue(date_valeur)
  }
  /**
   * Send CSV content to API
   */
  onSubmit = () => {
    // Activate spinner
    const filename = this.form.get('name').value
    const content = this.form.get('fileContent').value
    const commentaire = this.form.get('commentaire').value
    const motif = this.form.get('motif').value
    const qualite = this.form.get('qualite').value
    const date_valeur = this.form.get('date_valeur').value
    this.loading = true
    const messagesError: string[] = []
    // Get API data mapping
    const dataLabelMapping = {}
    for (const mapElement of environment.common.apiLabelMapping) {
      dataLabelMapping[mapElement.label] = mapElement.column
    }

    // Convert data (can take some time)
    // We use Papaparse to convert CSV to JSON
    const that = this
    this.papa.parse(content, {
      header: true,
      skipEmptyLines: 'greedy',
      // Arbitrary encoding ISO-8859-15 (Windows workstations)
      encoding: 'ISO-8859-15',
      delimiter: ';',
      complete: function (results) {
        // Papaparse build a list of object according to user headers
        // We convert the headers in order to sent it to API
        const dataToSend = {}

        let rub_error: number[]
        let line = 1
        const body = []
        loop1: for (const data of results.data) {
          const convertedData = {}
          const header = []

          for (const key of Object.keys(data)) {
            // Ignore empty columns
            let trimed_key: string
            try {
              trimed_key = decodeURIComponent(escape(key.trim()))
            } catch (error) {
              trimed_key = key.trim()
            }
            if (trimed_key === '') {
              continue
            }
            // Change object key
            if (trimed_key in dataLabelMapping) {
              convertedData[dataLabelMapping[trimed_key]] = data[key]
              header.push(dataLabelMapping[trimed_key])
            } else {
              // Object key do not exist in mapping: error
              console.error("Invalid header: '" + trimed_key + "'")
              console.error(dataLabelMapping)
              if (
                environment.common.apiLabelMapping.length <
                header.length + 1
              ) {
                messagesError.push(
                  'Le nombre de colonne du document ne correspond pas.\r\n'
                )
              } else {
                messagesError.push(
                  'Une ou plusieurs en-têtes du fichier sont invalides.\r\n' +
                    'Vérifiez la colonne: ' +
                    trimed_key
                )
              }
              break loop1
            }
          }
          body.push(Object.values(data))
          if (
            environment.common.apiLabelMapping.length !==
            Object.values(convertedData).length
          ) {
            messagesError.push(
              'Le nombre de colonne du document ne correspond pas.\r\n'
            )
            break
          }
          line++
          if (
            !convertedData['ANNEE'] ||
            convertedData['ANNEE'].length !== 4 ||
            !/^\d+$/.test(convertedData['ANNEE'])
          ) {
            messagesError.push(
              'La valeur Année à la ligne ' +
                line +
                ' est éronnée ("' +
                convertedData['ANNEE'] +
                '")!'
            )
          }
          if (
            convertedData['CODE_CONTRAT'].length !== 5 ||
            !(
              convertedData['CODE_CONTRAT'] ===
              convertedData['CODE_CONTRAT'].toUpperCase()
            )
          ) {
            messagesError.push(
              'La valeur Code Contrat à la ligne ' +
                line +
                ' est éronnée ("' +
                convertedData['CODE_CONTRAT'] +
                '")!'
            )
          }

          if (!/^[0-9][0-9]-[A-Z]/.test(convertedData['L_DOMAINE'])) {
            messagesError.push(
              'La valeur Libellé DF à la ligne ' +
                line +
                ' est invalide ("' +
                convertedData['L_DOMAINE'] +
                '") !'
            )
          }

          if (
            convertedData['CODE_DMN_FONC'].length !== 8 ||
            !(
              convertedData['CODE_DMN_FONC'] ===
              convertedData['CODE_DMN_FONC'].toUpperCase()
            )
          ) {
            messagesError.push(
              'La valeur Code DF à la ligne ' +
                line +
                ' est invalide ("' +
                convertedData['CODE_DMN_FONC'] +
                '")!'
            )
          } else {
            const cur_libel = that.listDmnFonc.filter(
              (x) => x[0] === convertedData['CODE_DMN_FONC']
            )
            const correct_lib = cur_libel.filter(
              (x) =>
                x[1].substr(0, 2) === convertedData['L_DOMAINE'].substr(0, 2)
            )
            if (!cur_libel.length) {
              messagesError.push(
                'La valeur Code DF à la ligne ' +
                  line +
                  ' n\'existe pas ("' +
                  convertedData['CODE_DMN_FONC'] +
                  '")!'
              )
            } else if (!correct_lib.length) {
              messagesError.push(
                'La valeur Libellé DF à la ligne ' +
                  line +
                  ' ne correspond pas au Code DF "' +
                  convertedData['CODE_DMN_FONC'] +
                  '" ! \nLibellé correspondant: ' +
                  cur_libel.map((x) => x[1])
              )
            }
          }

          const rub_regex = /^([A,E]?[0-9][0-9]|[A-D])-[A-Z]/

          try {
            if (
              !rub_regex.exec(
                convertedData['CODE_RUBRIQUE']
              ) ||
              !that.listRubrique.filter(
                (x) =>
                  x[0] ===
                  rub_regex
                    .exec(convertedData['CODE_RUBRIQUE'])[0]
                    .replace(/-.$/, '')
              ).length
            ) {
              if (!rub_error) {
                messagesError.push(
                  'Les codes rubriques valides sont: ' +
                    that.listRubrique.map((x) =>
                      x[0].indexOf('-') < 0 ? x[0] : ''
                    )
                )
                rub_error = []
              }
              rub_error.push(line)
              const rub = (rub_regex.exec(
                convertedData['CODE_RUBRIQUE']
              ) || [convertedData['CODE_RUBRIQUE'].substr(0, 3)])[0]
              messagesError.push(
                'La valeur Rubrique à la ligne ' +
                  line +
                  ' est éronnée (' +
                  rub +
                  ') !'
              )
            }
          } catch (e) {
            console.error(e)
            if (!rub_error) {
              rub_error = []
            }
            rub_error.push(line)
            messagesError.push(
              'La valeur Rubrique à la ligne ' + line + ' est éronnée !'
            )
          }

          const ss_regex = /^(([A,E]?[0-9]+|[A-D])-)+[\w]/

          if (!rub_error || rub_error.indexOf(line) < 0) {
            const ss = (ss_regex.exec(
              convertedData['SOUS_RUBRIQUE']
            ) || ['invalid'])[0]
            const rub = rub_regex.exec(
              convertedData['CODE_RUBRIQUE']
            )[0]
            const valid_ss = that.listRubrique
              .filter((x) => x[0].startsWith(rub.replace(/.$/, '')))
              .sort((x, y) => (x > y && 1) || -1)
            const current_ss = that.listRubrique.filter(
              (x) => x[0] === ss.replace(/-.$/, '')
            )
            if (ss === 'invalid' || !current_ss.length) {
              console.error(valid_ss)
              messagesError.push(
                'La sous-rubrique (' +
                  ss +
                  ') ligne ' +
                  line +
                  " n'existe pas, les sous-rubriques valides sont : " +
                  (valid_ss.map((x) => x[1]) || rub)
              )
            } else if (
              valid_ss.length &&
              !valid_ss.filter((x) => current_ss.indexOf(x) >= 0).length
            ) {
              messagesError.push(
                'La sous-rubrique (' +
                  ss +
                  ') ligne ' +
                  line +
                  ' est invalide, les sous-rubriques valides sont : ' +
                  valid_ss.map((x) => x[1])
              )
            } else if (
              !valid_ss.length &&
              rub.replace(/.$/, '') !== ss.replace(/.$/, '')
            ) {
              messagesError.push(
                'La valeur Sous rubrique (' +
                  ss +
                  ') à la ligne ' +
                  line +
                  ' ne correspond pas à la rubrique (' +
                  rub +
                  ') ! '
              )
            }
          }

          convertedData['VALEUR'] = convertedData['VALEUR'] + ''
          convertedData['VALEUR'] = convertedData['VALEUR'].replace(',', '.')
          // tslint:disable-next-line
          if (!(Number(convertedData['VALEUR']) == convertedData['VALEUR'])) {
            messagesError.push(
              'La valeur Montant à la ligne ' +
                line +
                ' est éronnée ("' +
                convertedData['VALEUR'] +
                '")!'
            )
          }

          for (const element of body) {
            element[7] = element[7] + ''
            element[7] = element[7].replace(',', '.')
          }

          dataToSend['filename'] = filename
          dataToSend['commentaire'] = commentaire
          dataToSend['motif'] = motif
          dataToSend['qualite'] = qualite
          dataToSend['date_valeur'] = date_valeur
          dataToSend['fields'] = header
          dataToSend['data'] = body
        }
        if (messagesError.length !== 0) {
          that.loading = false
        } else {
          that.cloudEndpointService
            .pushJsonCsvData(dataToSend)
            .then((response) => {
              that.loading = false
              that.info = new InfoMessage()
              that.info.message =
                'Le fichier a été envoyé.\n' +
                'Il est en cours de chargement et sera visible dans les rapports à J + 1.\n' +
                'Vous serez notifié par un email suite à son import. '
            })
            .catch((err) => {
              console.error(err)
              that.loading = false
              that.error = new ApiError()
              if (err.response) {
                if (err.response.data && 'Message' in err.response.data) {
                  that.error.errorMessage = err.response.data['Message']
                } else {
                  that.error.errorMessage = err.response
                }
              } else {
                that.error.errorMessage =
                  "Une erreur s'est produite au moment du chargement des données"
              }
              that.error.errorObject = err
            })
        }
      },
    })
    this.mess = messagesError
  }
  /**
   * Clear form data
   */
  onCancel = () => {
    // Reset stored file content value
    this.form.get('fileContent').setValue(null)
    this.form.get('name').setValue('')
    this.form.get('commentaire').setValue('')
    this.form.get('motif').setValue('')
    this.form.get('qualite').setValue('')
    this.form.get('date_valeur').setValue('')
    // Reset HTML element
    this.fileInput.nativeElement.value = ''
    this.commentaire.nativeElement.value = ''
    this.motif.nativeElement.value = ''
    this.qualite.nativeElement.value = ''
    this.date_valeur.nativeElement.value = ''
    this.mess = null
    this.loading = false
  }
}
