import {
  Component,
  ElementRef,
  AfterViewInit,
  ViewChild,
  Input,
  OnChanges,
} from '@angular/core'

import { UtilsService } from '../../service/utils.service'
import { InfoMessage } from '../../classes/infoMessage'

@Component({
  selector: 'trvs-vac-info-modal',
  templateUrl: './infoModal.html',
  styleUrls: ['./infoModal.css'],
})
export class InfoModalComponent implements OnChanges {
  @Input() info: InfoMessage

  // Info dialog
  @ViewChild('infoDialog', { static: false }) errorDialogElement: ElementRef
  @ViewChild('infoDialogTechnicalMessageCopyButton', { static: false })
  infoDialogTechnicalMessageCopyButtonElement: ElementRef

  /**
   * Basic constructor
   */
  constructor(private utils: UtilsService) {}

  /**
   * Display popup when attribute "info" changed.
   */
  ngOnChanges() {
    if (this.info) {
      $('#infoModal').modal('show')
      $('#closemod').click(() => $('#infoModal').modal('hide'))
    } else {
      $('#infoModal').modal('hide')
    }
  }

  closeModal() {
    $('#errorModal').modal('hide')
  }
}
