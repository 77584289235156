import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { LogoutComponent } from './public/auth/google-signin/google-signin.component'
import { HomeComponent, HomeLandingComponent } from './public/home.component'
import { CsvImportComponent } from './secure/csvImport/csvImport.component'
import { DocImportComponent } from './secure/docImport/docImport.component'
import { GlobalCepComponent } from './secure/globalCep/globalCep.component'
import { CepDocComponent } from './secure/cepDoc/cepDoc.component'
import { HelpComponent } from './secure/help/help.component'
import { InputDataComponent } from './secure/inputData/inputData.component'
import {
  SecureHomeComponent,
  SecureHomeLandingComponent,
} from './secure/secureHome.component'

const homeRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    component: HomeComponent,
    children: [{ path: '', component: HomeLandingComponent }],
  },
]

const secureHomeRoutes: Routes = [
  {
    path: '',
    redirectTo: '/secureHome',
    pathMatch: 'full',
  },
  {
    path: 'secureHome',
    component: SecureHomeComponent,
    children: [
      { path: '', component: SecureHomeLandingComponent },
      { path: 'inputData', component: InputDataComponent },
      { path: 'globalCep', component: GlobalCepComponent },
      { path: 'cepDoc', component: CepDocComponent },
      { path: 'csvImport', component: CsvImportComponent },
      { path: 'docImport', component: DocImportComponent },
      { path: 'logout', component: LogoutComponent },
      { path: 'help', component: HelpComponent },
    ],
  },
]

const routes: Routes = [
  {
    path: '',
    children: [
      ...homeRoutes,
      ...secureHomeRoutes,
      {
        path: '',
        component: HomeComponent,
      },
    ],
  },
]

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
