/**
 * This is the entry-way into the routing logic: the first component that's called when the app loads.
 */
import { Component, OnInit } from '@angular/core'
import { UserLoginService } from './service/user-login.service'
import { AwsService } from './service/aws.service'
import { environment } from 'src/environments/environment'
import * as firebase from 'firebase/app'
import { Router } from '@angular/router'

declare var window: any

@Component({
  selector: 'app-root',
  templateUrl: 'template/app.html',
})
export class AppComponent implements OnInit {
  envimg = environment.img

  constructor(
    public userService: UserLoginService,
    public awsService: AwsService,
    private router: Router
  ) {
    if (firebase.getApps().length === 0) {
      const config = {
        apiKey: environment.apiKey,
        authDomain: 'fr-ist-isteau-ddesk-finap-rec.firebaseapp.com',
      }
      firebase.initializeApp(config)
    }
  }

  logout() {
    window.google.accounts.id.revoke(environment.googleClientId)
    this.userService.onLogoutSuccess()
  }

  ngOnInit() {
    this.awsService.initAmplify()
    this.userService.isAuthenticated(this)
  }

  isLoggedIn(message: string, isLoggedIn: boolean) {
    console.log(
      'AppComponent: the user is authenticated: ' + isLoggedIn + ', ' + message
    )
    if (isLoggedIn) {
      this.router.navigate(['/secureHome'], { skipLocationChange: true })
    }
  }
}
