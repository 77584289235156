<div class="row">
  <mat-card class="mat-card">
    <mat-accordion>
      <!-- <button style="background-color: rgb(150, 148, 148); margin-left: 1790px" mat-fab>
                        <mat-icon>add</mat-icon>
                    </button>
                    <br/>
                    <br/>-->
      <div style="display: block; width: 100%">
        <mat-expansion-panel [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title> Filtrer par Code Contrat </mat-panel-title>
            <mat-panel-description> </mat-panel-description>
          </mat-expansion-panel-header>

          <mat-form-field
            style="width: 80%; margin: 0 10%; display: inline-block"
          >
            <mat-label>Contrat</mat-label>
            <input
              type="text"
              aria-label="contract"
              value=""
              matInput
              name="contract"
              ngDefaultControl
              [formControl]="contractControl"
              [matAutocomplete]="contractAC"
              #contract
              [(ngModel)]="selectedContract"
            />
            <button
              mat-button
              *ngIf="selectedContract"
              matSuffix
              mat-icon-button
              aria-label="Clear"
              (click)="selectedContract=''"
            >
              <mat-icon>close</mat-icon>
            </button>
            <mat-autocomplete #contractAC>
              <mat-option
                *ngFor="let element of contracts | async"
                [value]="element"
              >
                {{element}}
              </mat-option>
            </mat-autocomplete>
          </mat-form-field>

          <button
            style="display: block; margin: auto"
            mat-raised-button
            color="primary"
            (click)="sendFilter()"
          >
            Valider
          </button>
        </mat-expansion-panel>
      </div>
    </mat-accordion>

    <mat-progress-bar *ngIf="spinner" mode="indeterminate"></mat-progress-bar>

    <mat-table class="mat-table" [dataSource]="dataTable" matSort>
      <!--- Note that these columns can be defined in any order.
                              The actual rendered columns are set as a property on the row definition" -->
      <!-- Position Column -->

      <ng-container matColumnDef="__">
        <mat-header-cell
          style="flex: 0 0 50px"
          *matHeaderCellDef
          mat-sort-header
        >
        </mat-header-cell>
        <mat-cell
          style="flex: 0 0 50px"
          *matCellDef="let element; let i = index;"
        >
          <span id="{{i}}">&nbsp;</span>
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="code_contrat">
        <mat-header-cell
          style="flex: 0 0 100px"
          *matHeaderCellDef
          mat-sort-header
        >
          Code Contrat
        </mat-header-cell>
        <mat-cell style="flex: 0 0 100px" *matCellDef="let element">
          {{element.code_contrat}}
        </mat-cell>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="date_import">
        <mat-header-cell
          style="flex: 0 0 140px"
          *matHeaderCellDef
          mat-sort-header
        >
          Date d'import
        </mat-header-cell>
        <mat-cell style="flex: 0 0 140px" *matCellDef="let element">
          {{element.date_import}}
        </mat-cell>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="type_doc">
        <mat-header-cell
          style="flex: 0 0 220px"
          *matHeaderCellDef
          mat-sort-header
        >
          Type De Document
        </mat-header-cell>
        <mat-cell style="flex: 0 0 220px" *matCellDef="let element">
          {{listType[element.type_doc - 1][1]}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="fichier">
        <mat-header-cell
          style="flex: 0 0 220px"
          *matHeaderCellDef
          mat-sort-header
        >
          Nom de Fichier
        </mat-header-cell>
        <mat-cell style="flex: 0 0 220px" *matCellDef="let element">
          {{element.fichier}}
        </mat-cell>
      </ng-container>
      <ng-container matColumnDef="date_effet_doc">
        <mat-header-cell
          style="flex: 0 0 140px"
          *matHeaderCellDef
          mat-sort-header
        >
          Date d’effet
        </mat-header-cell>
        <mat-cell style="flex: 0 0 140px" *matCellDef="let element">
          {{element.date_effet_doc}}
        </mat-cell>
      </ng-container>

      <ng-container matColumnDef="url">
        <mat-header-cell
          style="flex: 0 0 150px"
          *matHeaderCellDef
          mat-sort-header
        >
        </mat-header-cell>
        <mat-cell style="flex: 0 0 150px" *matCellDef="let element;">
          <a href="{{element.url}}" target="_blank">Lien vers le document</a>
        </mat-cell>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
      <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
    </mat-table>
    <mat-paginator [pageSizeOptions]="[25, 50, 100]"></mat-paginator>
  </mat-card>

  <trvs-vac-error-modal [error]="error"></trvs-vac-error-modal>
</div>
