<!-- Navigation -->
<div>
    <div style="margin: 0 auto;">

        <nav mat-tab-nav-bar>
            <a mat-tab-link *ngFor="let link of navLinks" [routerLink]="link.link" routerLinkActive
                #rla="routerLinkActive" [active]="rla.isActive">  {{link.label}}

            </a>
        </nav>

    </div>


    <!-- /.navbar-static-side -->

<!-- Page Content -->
   
                <router-outlet></router-outlet>
            
<!-- /#page-wrapper -->

</div>
<!-- /#wrapper -->