
export const commonEnvironment = {
    apiLabelMapping: [
        { column: 'ANNEE', label: 'Année' },
        { column: 'CODE_CONTRAT', label: 'Code Contrat' },
        { column: 'CODE_DMN_FONC', label: 'Code DF' },
        { column: 'L_DOMAINE', label: 'Libellé DF' },
        { column: 'CODE_RUBRIQUE', label: 'Rubrique' },
        { column: 'SOUS_RUBRIQUE', label: 'Sous rubrique' },
        { column: 'UNITE', label: 'Unité' },
        { column: 'VALEUR', label: 'Montant' }
    ]

};
