<!-- Header -->
<a name="about"></a>
<div class="intro-header">
    <div class="container">

        <div class="row">
            <div style="margin: 0 auto;" >
                <img src="assets/img/vac-logo.png">
                <h1 >Bienvenue sur l'application VAC</h1>

                    <google-signin></google-signin>
            </div>
        </div>

    </div>
    <!-- /.container -->

</div>
<!-- /.intro-header -->
