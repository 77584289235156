import {
  Component,
  ElementRef,
  AfterViewInit,
  ViewChild,
  Input,
  OnChanges,
} from '@angular/core'

import { UtilsService } from '../../service/utils.service'
import { ApiError } from '../../classes/apiError'

@Component({
  selector: 'trvs-vac-error-modal',
  templateUrl: './errorModal.html',
  styleUrls: ['./errorModal.css'],
})
export class ErrorModalComponent implements OnChanges {
  @Input() error: ApiError

  // Error dialog
  @ViewChild('errorDialog', { static: false }) errorDialogElement: ElementRef
  @ViewChild('errorDialogTechnicalMessageCopyButton', { static: false })
  errorDialogTechnicalMessageCopyButtonElement: ElementRef

  /**
   * Basic constructor
   */
  constructor(private utils: UtilsService) {}

  /**
   * Display popup when attribute "error" changed.
   */
  ngOnChanges() {
    if (this.error) {
      $('#errorModal').modal('show')
    } else {
      $('#errorModal').modal('hide')
    }
  }

  closeModal() {
    $('#errorModal').modal('hide')
  }

  copyTechnicalDetails() {
    if (this.utils.isCopyToClipboardSupported()) {
      this.utils.copyToClipboard(this.error.technicalErrorMessage)
    }
  }
}
